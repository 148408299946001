import React from "react";
import styled from "styled-components/macro";
import { CONSTANTS, FullWidthButton } from "../../../utils/globals";
import FormGroup from "../../FormGroup";

function VerifyCode({ code, setCode, handleSubmit }) {
  return (
    <React.Fragment>
      <Title>Reset Password</Title>
      <Subtitle>We sent a code to your email</Subtitle>
      <Form onSubmit={handleSubmit}>
        <FormGroup
          state={code}
          setState={setCode}
          type="text"
          placeholder="code"
          title="Please enter the code"
          required
        />

        {/* <FullWidthButton type="button" onClick={handleSubmit}> */}
        <FullWidthButton
          type="button"
          onClick={handleSubmit}
          styles="margin-top: 10px;"
          disabled={code.length !== 6}
        >
          VERIFY CODE
        </FullWidthButton>
      </Form>
      <TextContainer>
        <Text
          className="clickable orange"
        >
          Resend Code
        </Text>
        
      </TextContainer>
    </React.Fragment>
  );
}

export default VerifyCode;

const Title = styled.h2`
  font-weight: 600;
  /* font-size: 2.5rem; */
  text-align: center;
  color: ${CONSTANTS.black};
  margin-bottom: 2.75rem;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Subtitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${CONSTANTS.black};
  text-align: center;
  margin-bottom: 50px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 15px;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${CONSTANTS.black};
  text-align: center;

  &.orange {
    color: ${CONSTANTS.orange};
  }

  &.clickable {
    cursor: pointer;
    user-select: none;
  }

  &.withUnderline {
    &::after {
      content: "";
      width: 0;
      height: 1px;
      display: block;
      position: relative;
      bottom: -5px;
      /* background-color: ${CONSTANTS.orange}; */
      background-color: #fff;
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
  }
`;
