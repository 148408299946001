import axios from "../../utils/axios";

export const submitContactEmail = async (name, email, topic, message) => {
  try {
    const response = await axios.post("/contact", {
      name,
      email,
      topic,
      message,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};
