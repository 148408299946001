import React, { useContext } from "react";
import styled from "styled-components/macro";
import {
  CONSTANTS,
  FullWidthButton,
  MODAL_TYPES,
} from "../../../../utils/globals";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../../../context/ModalContext";

function UpcomingBidsCard({ bid, token }) {
  function formatDate(date) {
    date = new Date(date) || new Date();
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = date?.toLocaleString("en-US", options);
    const [weekdayPart, datePart, timePart] = formattedDate.split(",");
    return [weekdayPart?.trim(), datePart?.trim(), timePart?.trim()];
  }

  const [startDay, startDate, startTime] = formatDate(bid?.startDate);
  const [endDay, endDate, endTime] = formatDate(bid?.endDate);

  const navigate = useNavigate();

  const { openModal } = useContext(ModalContext);

  return (
    <ItemContainer>
      <ItemWrapperRow>
        {/* <ItemInfoCol lg="9"> */}
        <ItemInfoCol>
          <ItemInfoWrapperRow className="text-center text-lg-start">
            <ItemImageContainerCol md="3" className="mx-auto">
              <ItemImage src={bid?.item.images[0]} />
            </ItemImageContainerCol>

            <ItemDetails className="col">
              <div className="info">
                {/* <h3>Item Name</h3> */}
                <h3>{bid?.item.name}</h3>
                <span>
                  {/* Join us for our Last Chance March Timed Auction. The sale is
                  online only and includes the passed lots from the March 4th,
                  2023 sale plus other added items. */}
                  {bid?.item.description}
                </span>
              </div>

              <div className="shop" style={{ opacity: 0 }}>
                <ShopImageContainer>
                  <img
                    src="https://picsum.photos/id/1/200/300"
                    alt="shop icon"
                  />
                </ShopImageContainer>
                <span>Shop</span>
              </div>
            </ItemDetails>
          </ItemInfoWrapperRow>
        </ItemInfoCol>

        <BidDate lg="3">
          <BidDateContentWrapper>
            <div>
              {/* <DateTitle>MAR 10</DateTitle>
              <DateTitle>10:00 PM EET</DateTitle> */}
              <DateTitle>{startDate}</DateTitle>
              <DateTitle>{startTime}</DateTitle>
            </div>

            <div>
              <DateSubTitle>
                Start:{" "}
                {`${startDay}, ${startDate}${startTime ? `, ${startTime}` : ""}`}
              </DateSubTitle>
              <DateSubTitle>
                End: {`${endDay}, ${endDate}${endTime ? `, ${endTime}` : ""}`}
              </DateSubTitle>
            </div>

            <FullWidthButton
              styles={`font-weight: 600; font-size: 20px;`}
              // onClick={() => navigate(`/bid/id/${bid._id}`)}
              // onClick={() => openModal(MODAL_TYPES.LOGIN)}
              onClick={() => {
                token
                  ? navigate(`/bid/id/${bid._id}`)
                  : openModal(MODAL_TYPES.SIGNUP);
              }}
            >
              {token ? "View Bid" : "Register Now"}
            </FullWidthButton>
          </BidDateContentWrapper>
        </BidDate>
      </ItemWrapperRow>
    </ItemContainer>
  );
}

export default UpcomingBidsCard;

const ItemContainer = styled(Container)`
  margin-bottom: 50px;
`;

const ItemWrapperRow = styled(Row)`
  min-height: 300px;
  /* padding: 30px; */
  background-color: ${CONSTANTS.white};
  box-shadow: ${CONSTANTS.hardShadow};
  border-radius: 15px;
`;

const ItemInfoCol = styled(Col)`
  margin: 25px 0 25px 30px;
  padding: 20px;
  background-color: ${CONSTANTS.lightgrey};
  border: 1px solid ${CONSTANTS.lightgrey2};
  border-radius: 15px;

  @media (max-width: 992px) {
    margin: 25px 30px;
  }
`;

const ItemInfoWrapperRow = styled(Row)`
  padding-left: 20px;

  @media (max-width: 992px) {
    padding-left: 0;
  }
`;

// const ItemImageContainerCol = styled.div`
const ItemImageContainerCol = styled(Col)`
  height: 210px;
  width: 210px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  overflow: hidden;
  margin-right: 40px !important;
  padding: 0;

  @media (max-width: 992px) {
    width: 100% !important;
    margin-right: 0;
  }

  @media (max-width: 490px) {
    margin-right: 0 !important;
  }
`;

const ItemImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const ItemDetails = styled.div`
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 992px) {
    gap: 20px;

    .info {
      display: flex;
      flex-direction: column;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    color: ${CONSTANTS.black};
    margin-bottom: 25px;
    margin-top: 25px;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    color: ${CONSTANTS.black};
  }

  .shop {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: ${CONSTANTS.black};
    }
  }
`;

const ShopImageContainer = styled.div`
  height: 50px;
  width: 50px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media (max-width: 992px) {
      object-fit: contain;
    }
  }
`;

const BidDate = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: pink; */

  @media (max-width: 992px) {
    margin-bottom: 25px;
  }
`;

const BidDateContentWrapper = styled.div`
  /* width: 50%; */
  width: 190px;
  height: 100%;
  background-color: ${CONSTANTS.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30.5px;
`;

const DateTitle = styled.h2`
  font-weight: 600;
  font-size: 22px;
  color: ${CONSTANTS.black};
  text-align: center;
`;

const DateSubTitle = styled.h4`
  font-weight: 600;
  font-size: 13px;
  color: ${CONSTANTS.black};
  text-align: center;
`;
