import React, { useState } from "react";
import styled from "styled-components/macro";
import { CONSTANTS, FullWidthButton } from "../../utils/globals";
import { Col, Container, Row } from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import { Select } from "antd";
import { submitContactEmail } from "./d_ContactUs";
import Spinner from "../../components/Spinner";
import { useEffect } from "react";

// General inquiry
// Item Inquiry
// Sell on Auctionable
// Help us Improve

const topicOptions = [
  {
    label: "General Inquiry",
    value: "General Inquiry",
  },
  {
    label: "Item Inquiry",
    value: "Item Inquiry",
  },
  {
    label: "Sell on Auctionable",
    value: "Sell on Auctionable",
  },
  {
    label: "Help us Improve",
    value: "Help us Improve",
  },
];

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, topic, message);
    setSubmitStatus(true);
    try {
      const response = await submitContactEmail(name, email, topic, message);
      console.log(response);
      setSubmitStatus(false);
    } catch (error) {
      console.log(error);
      setSubmitStatus(false);
    }
  };

  return (
    <Container fluid>
      <Container className="text-center my-5">
        <Row className="mb-5" />
        <Title>Love to hear from you,</Title>
        <Title>Get in touch !</Title>

        <Row className="mb-5" />

        <SubtitleWrapper>
          <Subtitle>
            Contact us! Participate in the discussion, ask a question, and let
            us know what you enjoy and what we can improve. Please get in touch
            with us if you have any comments.
          </Subtitle>
        </SubtitleWrapper>

        <Row className="mb-5" />
        <Row className="mb-5" />

        <form action="#" method="POST" onSubmit={handleSubmit}>
          <FormContainer
            style={{
              padding: "92px 78px",
              margin: "0 50px",
              border: `1px solid ${CONSTANTS.grey}`,
              borderRadius: "10px",
              backgroundColor: CONSTANTS.white,
              boxShadow: CONSTANTS.hardShadow,
            }}
          >
            <Row>
              <Col lg={5} style={{ padding: 0 }}>
                <FormGroup
                  state={name}
                  setState={setName}
                  title={"Your Name"}
                  styles="margin-bottom: 20px"
                  extraPadding
                />
                <FormGroup
                  state={email}
                  type={"email"}
                  setState={setEmail}
                  title={"Your Email"}
                  styles="margin-bottom: 20px"
                  extraPadding
                />
                <>
                  <InputTitle>Topic</InputTitle>
                  <Select
                    id="topic-select"
                    style={{
                      backgroundColor: CONSTANTS.lightgrey,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "5px",
                      border: `1px solid ${CONSTANTS.lightgrey2}`,
                      width: "100%",
                      borderRadius: "5px",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    bordered={false}
                    onChange={setTopic}
                    options={topicOptions}
                    defaultValue={topicOptions[0].label}
                  />
                </>
              </Col>

              <Col lg={2} style={{ padding: 0 }} className="mb-5 md-0" />

              <Col lg={5} style={{ padding: 0 }}>
                <FormGroup
                  state={message}
                  setState={setMessage}
                  title="Your Message"
                  multiline
                />
              </Col>
            </Row>

            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <FullWidthButton
                disabled={
                  submitStatus ||
                  name === "" ||
                  email === "" ||
                  topic === "" ||
                  message === ""
                }
                styles="width: 50%; height: 50px;"
              >
                Submit <Spinner condition={submitStatus} />
              </FullWidthButton>
            </Row>
          </FormContainer>
        </form>

        <SubtitleWrapper style={{ marginTop: 100 }}>
          <Subtitle>
            Phone Support: 70 - 026 614, Available 7 days a week, 9:00 am - 5:30
            pm (EST) Email Support: info@madripple.com fill out the form below
          </Subtitle>
        </SubtitleWrapper>
      </Container>
    </Container>
  );
}

export default ContactUs;

const Title = styled.p`
  font-size: 48px;
  font-weight: 600;
  color: ${CONSTANTS.black};
  margin-bottom: 0;
`;

const SubtitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Subtitle = styled.p`
  color: ${CONSTANTS.black};
  font-size: 20px;
  max-width: 825px;
  text-align: center;
`;

const FormContainer = styled(Container)`
  margin-left: 0 !important;
  & .ant-select-selection-item {
    text-align: left;
  }
`;

const InputTitle = styled.span`
  display: block;
  text-align: left;
  color: ${CONSTANTS.black};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 9px;
`;

