import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

function AllBids({ bids }) {
  return (
    <Container>
      <h1>All Bids</h1>

      <div style={{ backgroundColor: "pink" }}>
        {bids?.length > 0 &&
          bids?.map((bid) => {
            return (
              <div key={bid._id}>
                <h3>{bid.item.name}</h3>
                <Link to={`/bid/id/${bid._id}`}>View Bid</Link>
              </div>
            );
          })}
      </div>
    </Container>
  );
}

export default AllBids;

const Container = styled.div``;