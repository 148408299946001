import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../utils/axios";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { CONSTANTS, FullWidthButton, MODAL_TYPES } from "../../utils/globals";
import FormGroup from "../FormGroup";
import { ModalContext } from "../../context/ModalContext";
import OTPStep from "./OTPStep";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import { handleSignUp } from "./d_Auth";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("961");
  const [error, setError] = useState(null);
  const [signUpStatus, setSignUpStatus] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const { setModalType } = useContext(ModalContext);

  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    console.log(phone);
    e.preventDefault();

    handleSignUp(email, username, password, phone)
      .then((res) => {
        setToken(res);
        navigate("/");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <React.Fragment>
      <>
        <Title>
          Sign up and start
          <br />
          bidding
        </Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup
            state={email}
            setState={setEmail}
            type="email"
            placeholder="email"
            title="EMAIL ADDRESS"
            required
          />
          <FormGroup
            state={username}
            setState={setUsername}
            type="text"
            placeholder="username"
            title="USERNAME"
            required
          />
          <FormGroup
            state={phone}
            setState={setPhone}
            type="number"
            title="PHONE"
            required
            phoneGroup
          />
          <FormGroup
            state={password}
            setState={setPassword}
            type="password"
            placeholder="password"
            title="PASSWORD"
            required
            styles="margin-bottom: 1rem;"
          />

          <TermsContainer>
            <input
              type="checkbox"
              checked={isTermsAccepted}
              onChange={() => setIsTermsAccepted((prev) => !prev)}
              id="terms"
            />
            <label htmlFor="terms">
              I accept the Terms, Privacy Policy, and Cookie Policy.
            </label>
          </TermsContainer>

          {/* <FullWidthButton type="button" onClick={handleSubmit} disabled> */}
          <FullWidthButton
            disabled={
              !isTermsAccepted ||
              email === "" ||
              password === "" ||
              username === "" ||
              phone === ""
            }
          >
            START NOW
          </FullWidthButton>
        </Form>

        <TextContainer>
          <Text>
            Already have an account?{" "}
            <Text
              onClick={() => setModalType(MODAL_TYPES.LOGIN)}
              className="orange withUnderline clickable"
            >
              Log in
            </Text>
          </Text>
        </TextContainer>
      </>
    </React.Fragment>
  );
}

export default Signup;

const Title = styled.h1`
  font-weight: 600;
  /* font-size: 2.5rem;*/
  font-size: clamp(1.5rem, 2.8vw, 2.5rem);

  text-align: center;
  color: ${CONSTANTS.black};
  margin-bottom: 2.75rem;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TermsContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  color: ${CONSTANTS.black};

  & > input {
    height: 12px;
    width: 12px;
    border: 1px solid ${CONSTANTS.lightgrey2};
    outline: transparent;

    &:focus {
      border: 1px solid ${CONSTANTS.orange};
    }
  }

  & > label {
    font-weight: 600;
    font-size: 12px;
    user-select: none;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 15px;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${CONSTANTS.black};
  text-align: center;

  &.orange {
    color: ${CONSTANTS.orange};
  }

  &.clickable {
    cursor: pointer;
  }

  &.withUnderline {
    &::after {
      content: "";
      width: 0;
      height: 1px;
      display: block;
      position: relative;
      bottom: -5px;
      background-color: #fff;
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
  }
`;
