import React from "react";
import styled from "styled-components/macro";
import { CONSTANTS } from "../utils/globals";
import { useNavigate } from "react-router-dom";

function ItemGridContainer({ array }) {
  const navigate = useNavigate();

  function formatDate(date) {
    date = new Date(date);
    // get difference between date and now in seconds
    const diff = Math.floor((date - new Date()) / 1000);

    if (diff < 60) {
      return `${diff} seconds left`;
    } else if (diff < 3600) {
      return `${Math.floor(diff / 60)} minutes left`;
    } else if (diff < 86400) {
      return `${Math.floor(diff / 3600)} hours left`;
    } else if (diff < 604800) {
      return `${Math.floor(diff / 86400)} days left`;
    } else if (diff < 2628000) {
      return `${Math.floor(diff / 604800)} weeks left`;
    } else if (diff < 31536000) {
      return `${Math.floor(diff / 2628000)} months left`;
    }
  }

  function formatFeaturedDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  }

  return (
    <GridContainer>
      {array?.length > 0 &&
        array?.map((bid) => (
          <React.Fragment key={bid?._id}>
            {/* {true && console.log(bid)} */}
            <GridItemParent key={bid?._id}>
              <GridItem onClick={() => navigate(`/bid/id/${bid?._id}`)}>
                <ItemImgContainer>
                  <img src={bid?.item.images[0]} alt="featured bid" />
                </ItemImgContainer>
                <ItemInfoContainer>
                  <h3>{bid?.item.name}</h3>
                  {bid.featured && bid.featured === true ? (
                    <>
                      <h5 style={{ opacity: 0 }}>Shop</h5>
                      <span
                        style={{
                          fontSize: 12,
                          color: CONSTANTS.grey,
                          fontWeight: 700,
                        }}
                      >
                        {/* Ends on {formatFeaturedDate(bid?.endDate)} */}
                        {bid?.bidState === "UPCOMING"
                          ? `Starts on ${formatFeaturedDate(bid?.startDate)}`
                          : `Ends on ${formatFeaturedDate(bid?.endDate)}`}
                      </span>
                      {/* <h5>{bid?.item.brand && bid?.item.brand}</h5> */}
                    </>
                  ) : (
                    <>
                      <h5 style={{ opacity: 0 }}>Shop</h5>
                      <div>
                        <div>
                          <span className="price">$ {bid?.currentPrice}</span>
                          <span className="bidCount">
                            ({bid?.bidCount} bids)
                          </span>
                        </div>
                        <span className="endDate">
                          {formatDate(bid?.endDate)}
                        </span>
                      </div>
                    </>
                  )}
                </ItemInfoContainer>
              </GridItem>
            </GridItemParent>
          </React.Fragment>
        ))}
    </GridContainer>
  );
}

export default ItemGridContainer;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 23px;
  /* max-width: 1310px; */
`;

const GridItemParent = styled.div`
  width: 1fr;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GridItem = styled.div`
  cursor: pointer;
  height: 424px;
  /* width: 100%; */
  width: 306.75px;
  /* max-width: 300px; */
  background-color: ${CONSTANTS.white};
  box-shadow: ${CONSTANTS.mediumShadow};

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* gap: 25px; */
  /* center and gap || space between */
  justify-content: space-between;
  /* justify-content: center;
  gap: 20px; */
  padding: 30px;
  border-radius: 15px;

  transition: all 0.3s ease-in-out;
  transform: scale(1);

  &:hover {
    box-shadow: ${CONSTANTS.hardShadow};
    transform: scale(1.02);
    transition: all 0.3s ease-in-out;
  }
`;

const ItemImgContainer = styled.div`
  height: 250px;
  width: 250px;
  border-radius: 15px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const ItemInfoContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  color: ${CONSTANTS.black};

  & * {
    margin: 0;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
  }

  h5 {
    font-weight: 600;
    font-size: 16px;
    color: ${CONSTANTS.grey};
    margin: 3px 0;
  }

  /* span {
    font-weight: 600;
    font-size: 14px;
  } */

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    & .price {
      font-weight: 600;
      font-size: 20px;
      color: ${CONSTANTS.orange};
      margin-right: 5px;
    }

    & .bidCount {
      font-weight: 400;
      font-size: 10px;
      color: ${CONSTANTS.grey};
    }

    & .endDate {
      font-weight: 400;
      font-size: 14px;
      color: ${CONSTANTS.red};
    }
  }
`;
