import React, { useContext, useEffect, useState } from "react";
import {
  getAllOpenBids,
  getBidsByCategory,
} from "./AuctionComponents/d_Auctions";
import DisplayBids from "./AuctionComponents/DisplayBids";
import styled from "styled-components/macro";
import { CategoriesContext } from "../../context/CategoriesContext";
import { useLocation } from "react-router-dom";
import { NotFoundText } from "../../utils/globals";
import Spinner from "../../components/Spinner";

const sortOptions = [
  // {
  //   label: "1st menu item",
  //   key: "0",
  // },
  {
    label: "Ending Soon",
    value: "ending",
  },
  {
    label: "Lowest Price",
    value: "lowest",
  },
  {
    label: "Highest Price",
    value: "highest",
  },
];

function Auctions() {
  const [bids, setBids] = useState([]);
  const [categoriesLoadingState, setCategoriesLoadingState] = useState(true);
  const [selectedSortOption, setSelectedSortOption] = useState("ending");

  const { subNavbarLinks } = useContext(CategoriesContext);

  const location = useLocation();

  const sortBidsByEndingSoon = (bids) => {
    return bids.sort((a, b) => {
      return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
    });
  };

  const sortBidsByLowestPrice = (bids) => {
    return bids.sort((a, b) => {
      return a.currentPrice - b.currentPrice;
    });
  };

  const sortBidsByHighestPrice = (bids) => {
    return bids.sort((a, b) => {
      return b.currentPrice - a.currentPrice;
    });
  };

  const sortFunctions = {
    ending: sortBidsByEndingSoon,
    lowest: sortBidsByLowestPrice,
    highest: sortBidsByHighestPrice,
  };

  const handleChangeSortOption = (option) => {
    setSelectedSortOption(option);
    setBids(sortFunctions[option](bids));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let tempCategories = subNavbarLinks;
    if (location.hash) {
      const categoryName = tempCategories?.find(
        (category) => category.link === location.hash.slice(1)
      )?.name;

      setCategoriesLoadingState(true);
      getBidsByCategory(categoryName).then((bids) => {
        sortFunctions[selectedSortOption](bids);
        setCategoriesLoadingState(false);
        setBids(bids);
      });
    } else {
      setCategoriesLoadingState(true);
      getAllOpenBids().then((bids) => {
        setCategoriesLoadingState(false);
        sortFunctions[selectedSortOption](bids);
        setBids(bids);
      });
    }
  }, [location, location.hash, location.pathname, subNavbarLinks]);

  return (
    <>
      <CustomContainer hasNoItems={!(bids?.length > 0)}>
        {categoriesLoadingState ? (
          <>
            <Spinner />
          </>
        ) : bids && bids?.length > 0 ? (
          <DisplayBids
            bids={bids}
            sortOptions={sortOptions}
            selectedSortOption={selectedSortOption}
            handleChangeSortOption={handleChangeSortOption}
          />
        ) : (
          <NotFoundText>No Bids Found</NotFoundText>
        )}
      </CustomContainer>
    </>
  );
}

export default Auctions;

const CustomContainer = styled.div`
  width: 100%;
  flex: 1;

  ${({ hasNoItems }) =>
    hasNoItems
      ? `display: flex; align-items:center; justify-content: center; flex-grow: 1;`
      : ``}
`;
