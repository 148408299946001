import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { CONSTANTS } from "../../utils/globals";
import { useEffect } from "react";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Container className="text-center my-5">
        <Row className="mb-5" />

        <Title>Terms And Conditions</Title>

        <Row className="mb-5" />
        <Row className="mb-5" />

        <HowItWorksContainer className="text-start p-5 mx-5 ">
          <Col className="mx-auto">
            <SubTitle>Acceptance of Terms and Conditions:</SubTitle>
            <TermsText>
              By using our online auction store, you agree to abide by the
              following terms and conditions. These terms constitute a legally
              binding agreement between you (the user) and Auctionable. If you
              do not agree with these terms, please refrain from using our
              services.
            </TermsText>

            <SubTitle>Registration:</SubTitle>
            <TermsText>
              To participate in our online auctions, you must create an account
              and provide accurate, complete, and up-to-date information. You
              are responsible for maintaining the confidentiality of your
              account details and for all activities that occur under your
              account.
            </TermsText>
            <SubTitle>Bidding and Purchasing:</SubTitle>

            <TermsText>
              <ol>
                <li>
                  Bidding: By placing a bid on an item, you agree to purchase
                  that item if you have the highest winning bid at the end of
                  the auction. All bids are final and cannot be retracted.
                </li>
                <li>
                  Bid Increments: Our online auction store may have specific bid
                  increment rules in place, which will be clearly stated on the
                  auction listing.
                </li>
                <li>
                  Winning Bids: The highest bidder at the close of the auction
                  will be considered the winner. The auction store reserves the
                  right to cancel or reject any bid at its discretion.
                </li>
                <li>
                  Purchase Obligation: If you win an auction, you are obligated
                  to complete the purchase and pay the final bid price along
                  with any applicable taxes, fees, and delivery costs.
                </li>
                <li>
                  Failure to complete Point 4 can result in account suspension.
                </li>
              </ol>
            </TermsText>

            <SubTitle>Payments and Fees:</SubTitle>
            <TermsText>
              <ol>
                <li>
                  Payment: All payments for purchased items must be made within
                  a specified timeframe using the available payment methods.
                  Failure to make timely payment may result in the cancellation
                  of the purchase.
                </li>
                <li>
                  Fees: Our auction store may charge fees for listing items,
                  participating in auctions, or other services. These fees will
                  be clearly communicated to users and must be paid in
                  accordance with the specified terms.
                </li>
              </ol>
            </TermsText>

            <SubTitle>Delivery:</SubTitle>
            <TermsText>
              The auction store will provide delivery services for purchased
              items unless otherwise stated. Delivery costs will be communicated
              to the buyer.
            </TermsText>

            <SubTitle>Limitation of Liability:</SubTitle>
            <TermsText>
              The auction store shall not be liable for any direct, indirect,
              incidental, consequential, or punitive damages arising from the
              use or inability to use our services, including but not limited to
              losses related to bidding, purchasing, or delivery.
            </TermsText>

            <SubTitle>Modifications and Termination:</SubTitle>
            <TermsText>
              The auction store reserves the right to modify, suspend, or
              terminate any aspect of our services, including these terms and
              conditions, at any time without prior notice.
            </TermsText>
          </Col>
        </HowItWorksContainer>
      </Container>
    </Container>
  );
}

const HowItWorksContainer = styled(Row)`
  background-color: ${CONSTANTS.white};
  box-shadow: ${CONSTANTS.mediumShadow};
  border-radius: 15px;
`;

const Title = styled.p`
  font-size: 48px;
  font-weight: 600;
  color: ${CONSTANTS.black};
`;

const SubTitle = styled.p`
  color: ${CONSTANTS.black};
  font-weight: 600;
  font-size: 24px;
`;
const TermsText = styled.p`
  color: ${CONSTANTS.black};
  font-size: 20px;
`;
