import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components/macro";
import { CONSTANTS, NotFoundText, SectionTitle } from "../../../utils/globals";
import { useNavigate } from "react-router-dom";
import ItemGridContainer from "../../../components/ItemGridContainer";
import Spinner from "../../../components/Spinner";

function FeaturedBids({ featuredRef, featuredBids, featuredBidsState }) {
  return (
    <>
      <SectionTitle ref={featuredRef}>Featured Bids</SectionTitle>
      <Container fluid style={{ marginBottom: "50px" }}>
        <Container style={{ position: "relative", minHeight: "100px" }}>
          {featuredBidsState ? (
            <>
              <Spinner isCenteredAbsolutely fontSize={24} />
            </>
          ) : featuredBids?.length > 0 ? (
            <>
              <ItemGridContainer array={featuredBids} />
            </>
          ) : (
            <>
              <NotFoundText>No Featured Bids</NotFoundText>
            </>
          )}
        </Container>
      </Container>
    </>
  );
}

export default FeaturedBids;
