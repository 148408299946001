import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import gavel from "../images/auth/gavel.png";
import { ModalContext } from "../context/ModalContext";
import { CONSTANTS, MODAL_TYPES } from "../utils/globals";
import Login from "./AuthComponents/Login";
import Signup from "./AuthComponents/Signup";
import ForgetPassword from "./AuthComponents/ForgetPassword";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

function AuthModal() {
  const { isModalOpen, setIsModalOpen, modalType } = useContext(ModalContext);
  const { token } = useContext(AuthContext);

  const AuthRouter = () => {
    if (modalType === MODAL_TYPES.LOGIN) return <Login />;
    else if (modalType === MODAL_TYPES.SIGNUP) return <Signup />;
    else if (modalType === MODAL_TYPES.FORGOT_PASSWORD)
      return <ForgetPassword />;
  };

  useEffect(() => {
    if (token) {
      setIsModalOpen(false);
      // todo decode jwt. if not success, remove token from localstorag and context, redirect to login
    }
  }, [token]);

  return (
    <Modal
      centered
      show={isModalOpen}
      size="xl"
      onHide={() => setIsModalOpen(false)}
      dialogClassName="bootstrap-modal-dialog"
      contentClassName="bootstrap-modal-content"
    >
      <Container>
        <ImageContainer>
          <Image src={gavel} alt="gavel" />
        </ImageContainer>
        <FormContainer>
          <FormWrapper>
            {/* <Login /> */}
            <AuthRouter />
          </FormWrapper>
        </FormContainer>
      </Container>
    </Modal>
  );
}

export default AuthModal;

const Container = styled.div`
  width: 100%;
  height: 70dvh !important;
  display: flex;
  background-color: ${CONSTANTS.lightgrey};
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 40%;
  @media (max-width: 992px) {
    display: none;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const FormContainer = styled.div`
  /* height: 100%; */
  overflow: auto;
  height: 100%;
  width: 60%;
  /* flex-grow:; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const FormWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 992px) {
    width: 80%;
  }
  @media (min-height: 858px) {
    justify-content: center;
  }
`;
