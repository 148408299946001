import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import searchIcon from "../images/navbar/searchIcon.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ModalContext } from "../context/ModalContext";
import { CONSTANTS, MODAL_TYPES } from "../utils/globals";
import { Container } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import { AuthContext } from "../context/AuthContext";
import { CategoriesContext } from "../context/CategoriesContext";
import LogoName from "./LogoName";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Spinner from "./Spinner";
import {
  feelingLuckyPing,
  getRandomBid,
} from "../pages/Home/HomeComponents/d_Home";
import { getCategories } from "../pages/Auctions/AuctionComponents/d_Auctions";

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const midSectionLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Auctions",
    link: "/auctions",
  },
  {
    name: "How It Works",
    link: "/how-it-works",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
];

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const [decodedJwt, setDecodedJwt] = useState(null);
  const { openModal } = useContext(ModalContext);
  const { token, setToken } = useContext(AuthContext);
  const [logOutStatus, setLogOutStatus] = useState(false);
  const [categories, setCategories] = useState([]);
  const [feelingLuckyEnabled, setFeelingLuckyEnabled] = useState(false);
  const [getCategoriesStatus, setGetCategoriesStatus] = useState(false);

  const { subNavbarLinks, setSubNavbarLinks } = useContext(CategoriesContext);

  const decodeJwt = (token) => token && jwtDecode(token);

  const navigateToRandomBid = async () => {
    const response = await getRandomBid();
    navigate(`/bid/id/${response}`);
  };

  const homeSubNavbarLinks = [
    {
      name: "Featured",
      link: "featured",
    },
    {
      name: "Upcoming",
      link: "upcoming",
    },
    {
      element: (
        <FeelingLuckyBtn
          onClick={navigateToRandomBid}
          disabled={!feelingLuckyEnabled}
        >
          Im Feeling Lucky
        </FeelingLuckyBtn>
      ),
    },
  ];

  useEffect(() => {
    setSubNavbarLinks([
      {
        element: <Spin indicator={antIcon} size="large" color="red" />,
      },
    ]);
    getCategories().then((res) => {
      setCategories(res);
    });

    const fetchCategories = async () => {
      try {
        const response = await getCategories();
        setCategories(response || []);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchFeelingLuckEnabled = async () => {
      try {
        const response = await feelingLuckyPing();
        setFeelingLuckyEnabled(response.value);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFeelingLuckEnabled();
  }, []);

  useEffect(() => {
    // console.log(decodeJwt(token));
    if (token) {
      setDecodedJwt(decodeJwt(token));
    }
  }, [token]);

  useEffect(() => {
    setSubNavbarLinks([
      {
        element: <Spin indicator={antIcon} size="large" color="red" />,
      },
    ]);

    if (location.pathname === "/") {
      setSubNavbarLinks(homeSubNavbarLinks);
    } else if (
      location.pathname === "/auctions" ||
      location.pathname.includes("/bid/id/")
    ) {
      setSubNavbarLinks(categories);
    } else {
      setSubNavbarLinks([]);
    }
  }, [location.pathname, location.hash, categories]);

  return (
    <>
      {/* <NavbarContainer> */}
      <Container fluid>
        <TopSection>
          <HolderContainer>
            {/* <LogoContainer onClick={() => navigate("/")}>
              <LogoOrangeSection>AUCTION</LogoOrangeSection>
              <LogoGreySection>ABLE</LogoGreySection>
            </LogoContainer> */}
            <LogoName onClick={() => navigate("/")} />

            {/* <SearchContainer>
            <SearchInput placeholder="Search Items and Auction Houses" />
            <SearchIcon src={searchIcon} />
          </SearchContainer> */}

            {!token ? (
              <>
                <LoginJoinContainer>
                  <LoginBtn onClick={() => openModal(MODAL_TYPES.LOGIN)}>
                    LOG IN
                  </LoginBtn>
                  <JoinBtn onClick={() => openModal(MODAL_TYPES.SIGNUP)}>
                    JOIN
                  </JoinBtn>
                </LoginJoinContainer>
              </>
            ) : (
              <>
                {/* <h3>Hey, {decodedJwt.username}! </h3> */}
                <RightBarContainer>
                  <h4 className="px-4">
                    Hey, {decodedJwt && decodedJwt?.username}!
                  </h4>
                  <JoinBtn
                    onClick={() => {
                      setLogOutStatus(true);
                      localStorage.removeItem("token");
                      setToken(null);
                      setDecodedJwt(null);
                      setLogOutStatus(false);
                    }}
                  >
                    LOG OUT <Spinner condition={logOutStatus} />
                  </JoinBtn>
                </RightBarContainer>
              </>
            )}
          </HolderContainer>
        </TopSection>

        <MidSectionContainer>
          <MidSectionWrapper>
            {midSectionLinks?.map((link) => (
              <LinkWrapper key={link.name}>
                {/* <CustomLink className="isActive" key={link.name} to={link.link}> */}
                <NavLink
                  to={link.link}
                  className={({ isActive }) =>
                    isActive ? "isActive customLink" : "customLink"
                  }
                  end
                >
                  {link.name}
                </NavLink>
              </LinkWrapper>
            ))}
          </MidSectionWrapper>
        </MidSectionContainer>
        {subNavbarLinks?.length > 0 && (
          <>
            <BottomSectionContainer>
              <BottomSectionWrapper>
                {subNavbarLinks.map((link, idx) =>
                  !link.element ? (
                    <React.Fragment key={idx}>
                      <LinkWrapper key={link.name}>
                        <NavLink
                          to={
                            location.pathname === "/"
                              ? "#" + link.link
                              : (location.pathname === "/auctions" ||
                                  location.pathname.includes("/bid/id/")) &&
                                "/auctions#" + link.link
                          }
                          className={() => {
                            return "#" + link.link === location.hash
                              ? "isActive customLink"
                              : "customLink";
                          }}
                          end
                        >
                          {link.name}
                        </NavLink>
                      </LinkWrapper>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={idx}>{link.element}</React.Fragment>
                  )
                )}
              </BottomSectionWrapper>
            </BottomSectionContainer>
          </>
        )}
      </Container>
      {/* </NavbarContainer> */}
    </>
  );
}

export default Navbar;

const NavbarContainer = styled.div`
  width: 100%;
`;

const FeelingLuckyBtn = styled.button`
  background-color: ${CONSTANTS.orange};
  border-radius: 5px;
  border: none;
  outline: transparent;
  color: #fff;
  /* font-size: 1.2rem; */
  font-size: clamp(0.8rem, 1.5vw, 1.2rem);
  font-weight: 800;
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (min-width: 768px) {
    padding: 8px;
  }

  &:disabled {
    background-color: ${CONSTANTS.black};
    color: ${CONSTANTS.white};
    transition: all 0.2s ease-in-out;
    cursor: not-allowed;
  }
`;

const TopSection = styled.div`
  height: 100px;
  background-color: ${CONSTANTS.lightgrey};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4%;
`;
const HolderContainer = styled(Container)`
  height: 100px;
  background-color: ${CONSTANTS.lightgrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightBarContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  height: 100%;
  /* width: 14%; */
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const LogoGreySection = styled.span`
  color: ${CONSTANTS.black};
  font-size: 2.5rem;
  font-weight: 800;
`;

const LogoOrangeSection = styled.span`
  color: ${CONSTANTS.orange};
  font-size: 2.5rem;
  font-weight: 800;
`;

const SearchContainer = styled.div`
  height: 100%;
  width: 34%;
  position: relative;
  display: flex;
  align-items: center;
  /* margin: 0 100px; */
`;

const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  background-color: ${CONSTANTS.white};
  border-radius: 5px;
  border: none;
  outline: transparent;
  padding-left: 20px;
  margin: 0;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${CONSTANTS.grey};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${CONSTANTS.grey};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${CONSTANTS.grey};
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  right: 4%;
  height: 20px;
  width: 20px;
`;

const LoginJoinContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const JoinBtn = styled.button`
  height: 40px;
  background-color: ${CONSTANTS.orange};
  border-radius: 5px;
  border: none;
  outline: transparent;
  color: #fff;
  /* font-size: 1.2rem; */
  font-size: clamp(0.8rem, 1.2vw, 1.2rem);
  font-weight: 800;
  padding: 0 18px;
  cursor: pointer;
`;

const LoginBtn = styled(JoinBtn)`
  background-color: transparent;
  color: ${CONSTANTS.black};
  border: none;
  outline: transparent;
`;

const SectionContainer = styled.div`
  width: 100%;
  /* 18 0 12 0 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${CONSTANTS.white};

  & .customLink {
    display: inline-block;
    height: 100%;
    font-weight: 600;
    color: ${CONSTANTS.black};
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SectionWrapper = styled.div`
  /* width: 46%; */
  width: clamp(300px, 100%, 880px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const MidSectionContainer = styled(SectionContainer)`
  & .isActive {
    color: ${CONSTANTS.orange};
    border-bottom: 2px solid ${CONSTANTS.orange};
    // todo make border smaller in width, using after
  }
`;

const MidSectionWrapper = styled(SectionWrapper)`
  /* width: 58.4%; */
  padding: 0;
  height: 50px;
`;

const BottomSectionContainer = styled(SectionContainer)`
  border-top: 0.5px solid ${CONSTANTS.lightgrey2};
  /* background-color: ${CONSTANTS.lightgrey}; */
  padding: 13px;

  & .isActive {
    color: ${CONSTANTS.orange};
  }
`;

const BottomSectionWrapper = styled(SectionWrapper)`
  /* width: 45%; */
  padding: 18px 0 12px 0;
  justify-content: space-evenly;
`;

const LinkWrapper = styled.div`
  /* width: calc(100% / 6); */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BottomSectionLinkWrapper = styled(LinkWrapper)``;

const CustomLink = styled(NavLink)`
  display: inline-block;
  height: 100%;
  font-weight: 600;
  color: ${CONSTANTS.black};
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
`;
