import React, { createContext, useState } from 'react';

const CategoriesContext = createContext();

const CategoriesContextProvider = ({ children }) => {
  const [subNavbarLinks, setSubNavbarLinks] = useState([]);  

  return (
    <CategoriesContext.Provider value={{ subNavbarLinks, setSubNavbarLinks }}>
      {children}
    </CategoriesContext.Provider>
  );
};

export { CategoriesContextProvider, CategoriesContext };