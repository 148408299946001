import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import upArrow from "../../../images/icons/upArrow.png";
import shareIcon from "../../../images/icons/shareIcon.png";
import { Col, Container, Row } from "react-bootstrap";
import {
  CONSTANTS,
  FullWidthButton,
  MODAL_TYPES,
} from "../../../utils/globals";
import { ModalContext } from "../../../context/ModalContext";
import Spinner from "../../../components/Spinner";
import { useNavigate } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";

let DebounceInterval = null;

function ItemLanding({
  currentBid,
  setCurrentBid,

  tempBid,
  setTempBid,

  currentPrice,
  setCurrentPrice,

  dateLeft,
  setDateLeft,

  currentBidder,
  setCurrentBidder,
  handlePlaceBid,

  newBidAmount,
  setNewBidAmount,

  socketConnected,
  decodedJwt,

  activeImage,
  activeImageRef,
  imageWheelRef,
  wheelItems,
  handleActiveImage,
  handleNextImage,
  handlePreviousImage,

  isExploding,
  setIsExploding,
}) {
  const [loadingSetBid, setLoadingSetBid] = useState(false);
  const { openModal } = useContext(ModalContext);

  const navigate = useNavigate();

  function formatDate(date) {
    date = new Date(date);
    const currentDate = new Date();
    const timeDifference = date - currentDate;

    const millisecondsInSecond = 1000;
    const millisecondsInMinute = 60 * millisecondsInSecond;
    const millisecondsInHour = 60 * millisecondsInMinute;
    const millisecondsInDay = 24 * millisecondsInHour;
    const millisecondsInWeek = 7 * millisecondsInDay;
    const millisecondsInYear = 365 * millisecondsInDay;

    let formattedDate = "";

    const years = Math.floor(timeDifference / millisecondsInYear);
    if (years > 0) {
      formattedDate += years + "y, ";
    }

    const weeks = Math.floor(
      (timeDifference % millisecondsInYear) / millisecondsInWeek
    );
    if (weeks > 0) {
      formattedDate += weeks + "w, ";
    }

    const days = Math.floor(
      (timeDifference % millisecondsInWeek) / millisecondsInDay
    );
    if (days > 0) {
      formattedDate += days + "d, ";
    }

    const hours = Math.floor(
      (timeDifference % millisecondsInDay) / millisecondsInHour
    );
    if (hours > 0) {
      formattedDate += hours + "h, ";
    }

    const minutes = Math.floor(
      (timeDifference % millisecondsInHour) / millisecondsInMinute
    );
    if (minutes > 0) {
      formattedDate += minutes + "m, ";
    }

    const seconds =
      Math.floor((timeDifference % millisecondsInHour) / millisecondsInSecond) %
      60;
    if (seconds > 0) {
      formattedDate += seconds + "s";
    }
    return formattedDate.replace(/,\s*$/, "");
  }

  useEffect(() => {
    if (currentBid?.bidState === "OPEN") {
      DebounceInterval = setInterval(() => {
        if (new Date(currentBid?.endDate) < new Date()) {
          // window.location.reload();
          // currentBid?.bidState = "ENDED";
          setCurrentBid({ ...currentBid, bidState: "ENDED" });
          return;
        }
        setDateLeft(formatDate(currentBid?.endDate));
      }, 1000);
    } else if (currentBid?.bidState === "UPCOMING") {
      DebounceInterval = setInterval(() => {
        if (new Date(currentBid?.startDate) < new Date()) {
          window.location.reload();
          return;
        }
        setDateLeft(formatDate(currentBid?.startDate));
      }, 1000);
    } else if (currentBid?.bidState === "ENDED") {
      setDateLeft("");
      currentBidder === decodedJwt?.username && setIsExploding(true);
    } else {
      setDateLeft("");
    }

    return () => {
      clearInterval(DebounceInterval);
    };
  }, [currentBid]);

  function BidButton() {
    if (!decodedJwt) {
      return (
        <FullWidthButton
          className="mb-3 py-3"
          onClick={() => {
            openModal(MODAL_TYPES.LOGIN);
          }}
        >
          Login To Bid
        </FullWidthButton>
      );
    }

    if (currentBid?.bidState === "OPEN") {
      return (
        <>
          <FullWidthButton
            disabled={
              loadingSetBid ||
              currentBidder === decodedJwt?.username ||
              tempBid !== newBidAmount
            }
            styles="height: 100px"
            className="mb-3 py-2"
            onClick={async (e) => {
              setLoadingSetBid(true);
              await handlePlaceBid(e);
              setLoadingSetBid(false);
            }}
          >
            {currentBidder === decodedJwt?.username ? (
              <span className="py-0">You are The Last bidder</span>
            ) : loadingSetBid ? (
              <span className="py-0">Loading...</span>
            ) : (
              <>
                <span>
                  PLACE BID
                  <p className="p-0 m-0" style={{ fontSize: 30 }}>
                    ${" "}
                    {(parseFloat(currentPrice) + parseFloat(newBidAmount))
                      .toFixed(1)
                      .toLocaleString()}
                  </p>
                </span>
              </>
            )}
          </FullWidthButton>
        </>
      );
    } else if (currentBid?.bidState === "UPCOMING") {
      return (
        <>
          <FullWidthButton className="mb-3 py-3" disabled>
            <span className="py-0">Bid Now</span>
          </FullWidthButton>
        </>
      );
    } else if (currentBid?.bidState === "ENDED") {
      return (
        <>
          <h2 style={{ color: "black", textAlign: "center" }}>
            {!currentBidder
              ? "---"
              : currentBidder === decodedJwt?.username
              ? "You Won This Bid"
              : "This Bid Ended"}
          </h2>
        </>
      );
    }

    return null;
  }

  return (
    <>
      {/* <ProductLanding> */}
      {/* <ProductImagesContainer> */}
      <Row
        style={{
          backgroundColor: CONSTANTS.white,
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Col
          lg={5}
          xs={12}
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ProductImageWrapper>
            {activeImage ? (
              <>
                <img src={activeImage} alt={activeImage} ref={activeImageRef} />
              </>
            ) : (
              <>
                <Spinner fontSize={36} isCenteredAbsolutely />
              </>
            )}
          </ProductImageWrapper>
          <ImagesWheel>
            <Arrow src={upArrow} onClick={handlePreviousImage} />
            {/* {currentBid?.item?.images.map((item) => ( */}
            <div ref={imageWheelRef}>
              {wheelItems.map((item) => (
                <WheelItemWrapper
                  key={item}
                  className={activeImage === item ? "active" : ""}
                >
                  <img
                    src={item}
                    alt={item}
                    onClick={() => handleActiveImage(item)}
                  />
                </WheelItemWrapper>
              ))}
            </div>
            <Arrow
              src={upArrow}
              className="downArrow"
              onClick={handleNextImage}
            />
          </ImagesWheel>
        </Col>
        <Col lg={1} className="d-lg-flex d-none" style={{ minHeight: "400px" }}>
          {isExploding && <ConfettiExplosion />}
        </Col>

        <Col
          lg={5}
          xs={12}
          className="mt-5 mt-lg-0"
          style={{ minHeight: "400px" }}
        >
          {/* <BidInformationContainer> */}
          <BidInformationWrapper>
            <ItemName>
              <h1>{currentBid?.item.name}</h1>{" "}
              <div className="socketStatus">
                <span>{socketConnected ? "Online" : ""}</span>
                <SocketStatusCircle connected={socketConnected} />
              </div>
            </ItemName>
            <PlaceBidContainer>
              <BidEndDateContainer>
                <span>
                  {currentBid?.bidState === "OPEN"
                    ? "Ends in:"
                    : currentBid?.bidState === "UPCOMING"
                    ? "Starts in:"
                    : currentBid?.bidState === "ENDED" && "Ended"}
                </span>
                {/* <span className="clrRed">1d 1h 30m 40s !</span> */}
                <span className="clrRed">
                  {/* ? formatDate(currentBid?.endDate) */}
                  {/* ? formatDate(currentBid?.startDate) */}
                  {currentBid?.bidState === "OPEN"
                    ? dateLeft
                    : currentBid?.bidState === "UPCOMING"
                    ? dateLeft
                    : currentBid?.bidState === "ENDED" && ""}
                </span>
              </BidEndDateContainer>
              <PlaceBidWrapper>
                <div name="wrapper">
                  <CurrentBidContainer>
                    {/* <span>$ 120,000</span> */}
                    <span>$ {parseFloat(currentPrice).toFixed(1)}</span>
                    <CurrentBidderContainer>
                      <div className="top">
                        <span>Last Bidder</span>
                      </div>
                      <div className="bottom">
                        <span>
                          {!currentBidder
                            ? "---"
                            : currentBidder === decodedJwt?.username
                            ? "You"
                            : currentBidder}
                        </span>
                      </div>
                    </CurrentBidderContainer>
                  </CurrentBidContainer>
                  {/* <button onClick={() => setIsExploding((prev) => !prev)}>
                    Toggle Confetti
                  </button> */}

                  <YourBidContainer>
                    {/* <span>Bid Range</span> */}
                    <span>Your Bid:</span>
                    <span>
                      Min: ${currentBid?.minBidIncrement} - Max: $
                      {currentBid?.maxBidIncrement}
                    </span>
                    {/* <span>$ 130,000</span> */}
                  </YourBidContainer>
                  <BiddingInputContainer
                    className={
                      " " +
                      (currentBidder === decodedJwt?.username ||
                      !decodedJwt ||
                      currentBid?.bidState !== "OPEN"
                        ? "opacity-0"
                        : "")
                    }
                  >
                    <BidDisabledContainerPrice
                      value={parseFloat(currentPrice).toFixed(1)}
                      disabled
                    />
                    <BidDisabledContainerPlus value={"+"} disabled />
                    <BidAmountContainer
                      type="number"
                      disabled={
                        loadingSetBid || currentBidder === decodedJwt?.username
                      }
                      value={tempBid}
                      onChange={(e) => {
                        let clamped = Math.max(
                          currentBid?.minBidIncrement,
                          Math.min(currentBid?.maxBidIncrement, e.target.value)
                        );
                        setTempBid(e.target.value);
                        clearTimeout(DebounceInterval);
                        DebounceInterval = setTimeout(() => {
                          setNewBidAmount(clamped);
                          setTempBid(clamped);
                        }, 1000);
                      }}
                      placeholder=""
                      min={currentBid?.minBidIncrement}
                      max={currentBid?.maxBidIncrement}
                    />
                  </BiddingInputContainer>
                  <BidButton />
                </div>
              </PlaceBidWrapper>
            </PlaceBidContainer>
          </BidInformationWrapper>
          {/* </BidInformationContainer> */}
        </Col>
      </Row>
      {/* </ProductImagesContainer> */}
      {/* </ProductLanding> */}
      {/* <ShareProductContainer></ShareProductContainer> */}
    </>
  );
}

export default ItemLanding;

const ProductLanding = styled.div`
  width: 100%;
  display: flex;
  /* height: 38vh; */
  height: 25rem; //400px
  /* todo share this product container
  height: 43.5vh;
  background-color: pink;

  & > div {
    height: 87.35%;
  } */
`;

const SocketStatusCircle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  /* background-color: ${(props) => (props.connected ? "green" : "red")}; */

  background: ${({ connected }) => (connected ? "#43ff33" : "#f33")};
  box-shadow: ${({ connected }) =>
    connected
      ? "0px 0px 3px 5px rgba(67, 255, 51, 0.5)"
      : "0px 0px 3px 5px rgba(255, 51, 51, 0.5)"};
`;

const ProductImagesContainer = styled.div`
  width: 51%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

const ProductImageWrapper = styled.div`
  position: relative;
  width: 79%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // ? could be contain

    @media (max-width: 768px) {
      object-fit: contain;
    }
  }
`;

const ImagesWheel = styled.div`
  width: 16%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  & > div {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 7px;

    & > div {
      flex-shrink: 0;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
      display: none; /* hide scrollbar */
    }
  }
`;

const Arrow = styled.img`
  width: 30px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  user-select: none;

  &.downArrow {
    transform: rotate(180deg);
  }
`;

const WheelItemWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  height: 20%;
  overflow: hidden;
  user-select: none;

  &.active {
    border: 2px solid ${CONSTANTS.orange};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    @media (max-width: 768px) {
      object-fit: contain;
    }
  }
`;

const BidInformationContainer = styled.div`
  width: 49%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BidInformationWrapper = styled.div`
  /* width: 82%; */
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8%;
  box-shadow: ${CONSTANTS.mediumShadow};
  border-radius: 15px;
  /* background-color: pink; */
`;

const ItemName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  & > h1 {
    color: ${CONSTANTS.black};
    font-size: 2.25rem;
    font-weight: 600;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #43ff33;
  }
`;

const PlaceBidContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: 1; */
`;

const BiddingInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BidEndDateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  color: ${CONSTANTS.black};
  font-weight: 600;
  .clrRed {
    color: ${CONSTANTS.red};
  }
`;

const PlaceBidWrapper = styled.div`
  width: 100%;
  /* flex: 1; */
  /* background-color: ${CONSTANTS.lightgrey}; */
  border-radius: 10px;
  display: flex;
  justify-content: center;

  & > div {
    /* width: 88.5%; */
    width: 100%;
    height: 100%;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const CurrentBidContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${CONSTANTS.lightgrey2};
  border-radius: 15px;
  padding: 8px;

  & > span {
    font-weight: 600;
    font-size: 2.1rem;
    padding-left: 10px;
    color: ${CONSTANTS.black};
  }
`;

const CurrentBidderContainer = styled.div`
  background-color: ${CONSTANTS.lightgrey};
  color: ${CONSTANTS.black};

  height: 49px;
  width: 101px;
  box-shadow: ${CONSTANTS.softShadow};
  font-size: 0.75rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  .top {
    width: 100%;
    height: 50%;
    border-radius: 5px 5px 0px 0px;
    background-color: ${CONSTANTS.orange};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom {
    width: 100%;
    height: 50%;
    flex-grow: 1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const YourBidContainer = styled.div`
  /* height: 54%; */
  width: 100%;
  padding: 20px 8px 0px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${CONSTANTS.black};
  font-size: 0.75rem;
  font-weight: 600;
  /* gap: 22px; */
`;

// const BidAmountContainer = styled.div`
const BidAmountContainer = styled.input`
  background-color: #fff;
  border-radius: 5px;
  outline: transparent;
  padding: 10px 0;

  font-weight: 400;
  font-size: 14px;
  color: ${CONSTANTS.black};
  border: 1px solid ${CONSTANTS.lightgrey2};
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BidDisabledContainerPrice = styled.input`
  background-color: #fff;
  border-radius: 5px;
  outline: transparent;
  padding: 10px 0;

  font-weight: 400;
  font-size: 14px;
  color: ${CONSTANTS.black};
  border: 1px solid ${CONSTANTS.lightgrey2};
  background-color: ${CONSTANTS.lightgrey};
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const BidDisabledContainerPlus = styled.input`
  background-color: #fff;
  width: 10%;
  padding: 10px 0;
  border-radius: 5px;
  outline: transparent;

  font-weight: 400;
  font-size: 14px;
  color: ${CONSTANTS.black};
  border: 1px solid ${CONSTANTS.lightgrey2};
  background-color: ${CONSTANTS.lightgrey};
  margin: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

// const FullWidthButton = styled.button`
//   width: 100%;
//   border: none;
//   outline: transparent;
//   background-color: #FF9900;
//   color: white;
//   font-weight: 800;
//   font-size: 14px;
//   padding: 10px 0;
//   border-radius: 5px;

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;

const ShareProductContainer = styled.div`
  width: 100%;
  height: 50px;
`;
