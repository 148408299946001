import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components/macro";
import {
  CONSTANTS,
  FullWidthButton,
  NotFoundText,
  SectionTitle,
} from "../../../../utils/globals";
import Dropdown from "../../../../components/Dropdown";
import UpcomingBidsCard from "./UpcomingBidsCard";
import Spinner from "../../../../components/Spinner";

function UpcomingBids({
  upcomingRef,
  upcomingBids,
  upcomingBidsState,
  selectedSortOption,
  handleChangeSortOption,
  sortOptions,
  token,
}) {
  // // a bid has the properties: _id, title, description
  // /**
  //  * @type {[{ _id: string, title: string, description: string }[], (bids: { _id: string, title: string, description: string }[]) => void]}
  //  */

  return (
    <>
      <SectionTitle ref={upcomingRef}>Upcoming Auctions</SectionTitle>
      <Container fluid>
        <Container style={{ position: "relative", minHeight: "100px" }}>
          <SortContainer>
            <span>Sort By</span>
            <Dropdown
              sortOptions={sortOptions}
              selectedSortOption={selectedSortOption}
              handleChange={handleChangeSortOption}
            />
          </SortContainer>
          {upcomingBidsState ? (
            <>
              <Spinner isCenteredAbsolutely fontSize={24} />
            </>
          ) : upcomingBids?.length > 0 ? (
            <>
              {upcomingBids?.map((bid) => (
                <UpcomingBidsCard bid={bid} key={bid._id} token={token} />
              ))}
            </>
          ) : (
            <>
              <NotFoundText>No Upcoming Bids</NotFoundText>
            </>
          )}
        </Container>
      </Container>
    </>
  );
}

export default UpcomingBids;

const SortContainer = styled.div`
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 36px;
  gap: 20px;
  span {
    font-weight: 700;
    font-size: 16px;
    color: ${CONSTANTS.black};
  }
`;
