import React from "react";
import styled from "styled-components/macro";
import { CONSTANTS } from "../utils/globals";

function LogoName({ onClick, containerStyles }) {
  return (
    <LogoContainer onClick={onClick} styles={containerStyles}>
      <LogoOrangeSection>AUCTION</LogoOrangeSection>
      <LogoGreySection>ABLE</LogoGreySection>
    </LogoContainer>
  );
}

export default LogoName;

const LogoContainer = styled.div`
  /* height: 100%; */
  /* width: 14%; */
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: 800;
  /* font-size: 2.5rem;   */
  font-size: clamp(1.2rem, 2.5vw, 2.5rem);  

  ${({ styles }) => styles}
`;

const LogoGreySection = styled.span`
  color: ${CONSTANTS.black};
`;

const LogoOrangeSection = styled.span`
  color: ${CONSTANTS.orange};
`;
