import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import { CONSTANTS, FullWidthButton, MODAL_TYPES } from "../../utils/globals";
import FormGroup from "../FormGroup";
import { ModalContext } from "../../context/ModalContext";
import { handleLogin } from "./d_Auth";
import Spinner from "../Spinner";

// todo error state and error message rendering

function Login() {
  const [phone, setPhone] = useState("961");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loginStatus, setLoginStatus] = useState(false);
  const { setToken } = useContext(AuthContext);
  const { setModalType } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginStatus(true);
    handleLogin(phone, password)
      .then((res) => {
        setToken(res);
        setLoginStatus(false);
      })
      .catch((err) => {
        console.log("err", err);
        setError(err.response.data.data);
        setLoginStatus(false);
      });
  };

  return (
    <React.Fragment>
      <Title>Welcome to Auctionable</Title>
      <Form onSubmit={handleSubmit}>
        <FormGroup
          state={phone}
          setState={setPhone}
          type="number"
          placeholder="phone"
          title="PHONE NUMBER"
          required
          phoneGroup
        />
        <FormGroup
          state={password}
          setState={setPassword}
          type="password"
          placeholder="password"
          title="PASSWORD"
          required
        />

        {error && <ErrorText>{error}</ErrorText>}

        <FullWidthButton
          // onClick={handleSubmit}
          disabled={phone === "" || password === ""}
        >
          LOG IN <Spinner condition={loginStatus} color="white" />
        </FullWidthButton>
      </Form>

      <TextContainer>
        <Text
          className="clickable"
          onClick={() => setModalType(MODAL_TYPES.FORGOT_PASSWORD)}
        >
          Forgot Password?
        </Text>
        <Text>
          Don't have an account?{" "}
          <Text
            onClick={() => setModalType(MODAL_TYPES.SIGNUP)}
            className="orange withUnderline clickable"
          >
            Join
          </Text>
        </Text>
      </TextContainer>
    </React.Fragment>
  );
}

export default Login;

const Title = styled.h1`
  font-weight: 600;
  /* font-size: 2.5rem; */
  text-align: center;
  color: ${CONSTANTS.black};
  margin-bottom: 2.75rem;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ErrorText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${CONSTANTS.red};
  text-align: center;
  margin-bottom: 1.5rem;

`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 15px;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${CONSTANTS.black};
  text-align: center;

  &.orange {
    color: ${CONSTANTS.orange};
  }

  &.clickable {
    cursor: pointer;
    user-select: none;
  }

  &.withUnderline {
    &::after {
      content: "";
      width: 0;
      height: 1px;
      display: block;
      position: relative;
      bottom: -5px;
      /* background-color: ${CONSTANTS.orange}; */
      background-color: #fff;
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
  }
`;
