import React from "react";
import styled from "styled-components/macro";
import { CONSTANTS } from "../../../utils/globals";
import { Badge, Tag } from "antd";
import * as Icon from "react-bootstrap-icons";

function ItemInformation({ currentBid }) {
  return (
    <Container>
      <LeftColumn>
        <SectionTitle>
          <h3>Item Details</h3>
        </SectionTitle>
        <DetailsContainer>
          <DetailsGroup>
            <DetailsTitle>Description</DetailsTitle>
            <Details>{currentBid?.item.description}</Details>
          </DetailsGroup>
          <DetailsGroup>
            <DetailsTitle>Condition</DetailsTitle>
            <Details>
              <BigTag
                color={currentBid?.item.condition === "New" ? "green" : "grey"}
              >
                {currentBid?.item.condition}
                {currentBid?.item.delivery === "New" ? (
                  <Icon.Stars
                    className="mb-1 ms-1"
                    size={13}
                    color={"gold"}
                  />
                ) : (
                  <></>
                )}
              </BigTag>
            </Details>
          </DetailsGroup>
          <DetailsGroup>
            <DetailsTitle>Category</DetailsTitle>
            <Details>
              <BigTag color={"orange"}>{currentBid?.item.category}</BigTag>
            </Details>
          </DetailsGroup>
          <DetailsGroup>
            <DetailsTitle>Pick Up Location</DetailsTitle>
            <Details>
              <BigTag color={"orange"}>
                {currentBid?.item.pickupLocation}
                <Icon.GeoAltFill
                  className="mb-1 ms-1"
                  size={13}
                  color={CONSTANTS.orange}
                />
              </BigTag>
            </Details>
          </DetailsGroup>
          <DetailsGroup>
            <DetailsTitle>Delivery</DetailsTitle>
            <Details>
              <BigTag color={currentBid?.item.delivery ? "green" : "grey"}>
                {currentBid?.item.delivery ? "Available" : "Not Available"}
                {currentBid?.item.delivery ? (
                  <Icon.HandThumbsUpFill
                    className="mb-1 ms-1"
                    size={13}
                    color={"green"}
                  />
                ) : (
                  <></>
                )}
              </BigTag>
            </Details>
          </DetailsGroup>
        </DetailsContainer>
      </LeftColumn>
    </Container>
  );
}

export default ItemInformation;

const BigTag = styled(Tag)`
  font-size: 16px;
  padding: 2px 8px;
  font-weight: 600;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  /* background-color: ${CONSTANTS.white}; */
  /* background-color: pink; */

  /* margin-top: 2rem; //with the share container */
  /* margin-top: 3rem; //without the share container */
`;

const SectionTitle = styled.div`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid ${CONSTANTS.lightgrey2};

  & > h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${CONSTANTS.black};
  }
`;

const LeftColumn = styled.div`
  /* width: 51%; */
  width: 100%;
`;

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  gap: 26px;
`;

const DetailsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DetailsTitle = styled.div`
  font-weight: 400;
  font-size: 1.25rem;
  color: ${CONSTANTS.black};
`;

const Details = styled.div`
  font-size: 1rem;
  color: ${CONSTANTS.black};
`;

const RightColumn = styled.div`
  width: 49%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RightColumnWrapper = styled.div`
  width: 82%;
  height: 100%;
  align-self: flex-end;
  /* background-color: pink; */
`;

const ShipmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  margin-top: 15px;
`;

const ShipmentPoints = styled.div`
  display: flex;
  gap: 13px;

  font-weight: 600;
  font-size: 0.875rem;
`;

const RedBox = styled.div`
  height: 24px;
  width: 24px;
  background-color: #ff0000;
`;
