import { Select, Space } from "antd";
import { CONSTANTS } from "../utils/globals";
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
const Dropdown = ({ selectedSortOption, sortOptions, handleChange }) => {
  return (
    <Space wrap>
      <Select
        style={{
          backgroundColor: CONSTANTS.white,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 20px",
          border: `1px solid ${CONSTANTS.grey}`,
          width: "100%",
          borderRadius: "5px",
          cursor: "pointer",
          userSelect: "none",
        }}
        bordered={false}
        onChange={handleChange}
        options={sortOptions}
        defaultValue={sortOptions[0].label}
      />
    </Space>
  );
};
export default Dropdown

/* 
  backgroundColor: CONSTANTS.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px",
    border: `1px solid ${CONSTANTS.grey}`,
    width: "100%",    
    borderRadius: "5px",
    cursor: "pointer",
    userSelect: "none",
*/
