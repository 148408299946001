import React, { useContext } from "react";
import styled from "styled-components/macro";
import {
  CONSTANTS,
  FullWidthButton,
  MODAL_TYPES,
} from "../../../utils/globals";
import FormGroup from "../../FormGroup";
import { ModalContext } from "../../../context/ModalContext";
import Spinner from "../../Spinner";

function ResetPassword({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handleSubmit,
  resetPasswordStatus,
}) {
  const { setModalType } = useContext(ModalContext);

  return (
    <React.Fragment>
      <Title>Reset Password</Title>
      <Form onSubmit={handleSubmit}>
        <FormGroup
          state={password}
          setState={setPassword}
          type="password"
          placeholder="code"
          title="Enter new password"
          required
        />

        <FormGroup
          state={confirmPassword}
          setState={setConfirmPassword}
          type="password"
          placeholder="code"
          title="Confirm password"
          required
        />

        {/* <FullWidthButton type="button" onClick={handleSubmit}> */}
        <FullWidthButton
          type="button"
          onClick={handleSubmit}
          styles="margin-top: 10px;"
        >
          <Spinner condition={resetPasswordStatus} /> RESET
        </FullWidthButton>
      </Form>
    </React.Fragment>
  );
}

export default ResetPassword;

const Title = styled.h2`
  font-weight: 600;
  /* font-size: 2.5rem; */
  text-align: center;
  color: ${CONSTANTS.black};
  margin-bottom: 2.75rem;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
