import axios from "../../utils/axios";

export const handleLogin = async (phone, password) => {
  try {
    console.log("handleLogin");
    const response = await axios.post("/auth/login", {
      phone: "+" + phone,
      password: password,
    });
    localStorage.setItem("token", response.data.token);
    return response.data.token;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const handleSignUp = async (email, username, password, phone) => {
  try {
    const response = await axios.post("/auth/register", {
      email: email,
      username: username,
      password: password,
      phone: "+" + phone,
    });
    localStorage.setItem("token", response.data.token);
    return response.data.token;
    // setToken(response.data.token);
    // navigate("/");
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};
