import axios from "../../../utils/axios";

export const getAllOpenBids = async () => {
  try {
    const response = await axios.get("/bid/open");
    return response.data.bids;
  } catch (error) {
    console.log(error);
  }
};

export const getCategories = async () => {
  try {
    const response = await axios.get("/category");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBidsByCategory = async (categoryName) => {
  try {
    const response = await axios.get(`/bid/category?name=${categoryName}`);
    console.log(response)
    return response.data.bids;
  } catch (error) {
    console.log(error);
  }
}
