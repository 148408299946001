import React from "react";
import styled from "styled-components/macro";
import { CONSTANTS, FullWidthButton } from "../../../utils/globals";
import FormGroup from "../../FormGroup";

function EnterEmail({ email, setEmail, handleSubmit }) {
  return (
    <React.Fragment>
      <Title>Reset Password</Title>
      <Form onSubmit={handleSubmit}>
        <FormGroup
          state={email}
          setState={setEmail}
          type="email"
          placeholder="email"
          title="Enter your email"
          required
        />

        <FullWidthButton
          type="button"
          onClick={handleSubmit}
          disabled={email === ""}
          styles="margin-top: 10px;"
        >
          SEND CODE
        </FullWidthButton>
      </Form>
    </React.Fragment>
  );
}

export default EnterEmail;

const Title = styled.h2`
  font-weight: 600;
  /* font-size: 2.5rem; */
  text-align: center;
  color: ${CONSTANTS.black};
  margin-bottom: 2.75rem;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 15px;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${CONSTANTS.black};
  text-align: center;

  &.orange {
    color: ${CONSTANTS.orange};
  }

  &.clickable {
    cursor: pointer;
  }

  &.withUnderline {
    &::after {
      content: "";
      width: 0;
      height: 1px;
      display: block;
      position: relative;
      bottom: -5px;
      background-color: #fff;
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
  }
`;
