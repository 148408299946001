import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components/macro";
import ItemGridContainer from "../../../components/ItemGridContainer";
import { CONSTANTS, SectionTitle } from "../../../utils/globals";
import Dropdown from "../../../components/Dropdown";
// import DownArrow from "../../../images/icons/DropdownArrow.png"

function DisplayBids({
  bids,
  sortOptions,
  selectedSortOption,
  handleChangeSortOption,
}) {
  return (
    <>
      <SectionTitleContainer>
        <Row>
          <Col lg={4}></Col>
          <Col lg={4}>
            <SectionTitle>Open Bids</SectionTitle>
          </Col>
          <Col lg={4} className="sortContainer my-5">
            <SortWrapper>
              <span>Sort By</span>
              <Dropdown
                sortOptions={sortOptions}
                selectedSortOption={selectedSortOption}
                handleChange={handleChangeSortOption}
              />
            </SortWrapper>
          </Col>
        </Row>
      </SectionTitleContainer>
      <Container fluid>
        <Container>
          <ItemGridContainer array={bids} />
        </Container>
      </Container>
    </>
  );
}

export default DisplayBids;

const SectionTitleContainer = styled(Container)`
  & .sortContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  gap: 20px;

  span {
    font-weight: 700;
    font-size: 16px;
    color: ${CONSTANTS.black};
  }
`;
