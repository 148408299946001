import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function Spinner({
  fontSize = 18,
  color = "black",
  condition = true,
  isCenteredAbsolutely = false,
}) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: fontSize,
      }}
      spin
    />
  );

  return (
    <>
      {condition && (
        <Spin
          style={{
            marginLeft: 10,
            color: color,
            position: isCenteredAbsolutely ? "absolute" : "relative",
            top: isCenteredAbsolutely ? "50%" : "0",
            left: isCenteredAbsolutely ? "50%" : "0",
            transform: isCenteredAbsolutely ? "translate(-50%, -50%)" : "none",
          }}
          indicator={antIcon}
          size="large"
        />
      )}
    </>
  );
}

export default Spinner;
