import React, { createContext, useState } from 'react';
import AuthModal from '../components/AuthModal';

const ModalContext = createContext();

const ModalContextProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState();
  const [modalType, setModalType] = useState("");

  const openModal = (modalType) => {
    setModalType(modalType);
    setIsModalOpen(true);
  }

  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen, openModal, modalType, setModalType }}>
      {children}
      <AuthModal />
    </ModalContext.Provider>
  );
};

export { ModalContextProvider, ModalContext };