import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import AllBids from "./HomeComponents/AllBids";
import Banner from "./HomeComponents/Banner";
import FeaturedBids from "./HomeComponents/FeaturedBids";
import {
  GetBanners,
  getAllBids,
  getFeaturedBids,
  getRandomBid,
  getUpcomingBids,
} from "./HomeComponents/d_Home";
import { CategoriesContext } from "../../context/CategoriesContext";
import UpcomingBids from "./HomeComponents/UpcomingBids/UpcomingBids";
import { CONSTANTS } from "../../utils/globals";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const sortOptions = [
  // {
  //   label: "1st menu item",
  //   key: "0",
  // },
  {
    label: "Nearest",
    value: "nearest",
  },
  {
    label: "Farthest",
    value: "farthest",
  },
];

function Home() {
  const [bids, setBids] = useState([]);
  const [banners, setBanners] = useState([]);
  const [upcomingBids, setUpcomingBids] = useState([]);
  const [upcomingBidsState, setUpcomingBidsState] = useState(true);
  const [featuredBidsState, setFeaturedBidsState] = useState(true);
  const [featuredBids, setFeaturedBids] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState("nearest");

  const { token } = useContext(AuthContext);
  const featuredRef = useRef();
  const upcomingRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const sortBidsByNearestStartDate = (bids) => {
    return bids.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });
  };

  const sortBidsByFarthestStartDate = (bids) => {
    return bids.sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });
  };

  const sortFunctions = {
    nearest: sortBidsByNearestStartDate,
    farthest: sortBidsByFarthestStartDate,
  };

  const handleChangeSortOption = (option) => {
    setSelectedSortOption(option);
    setUpcomingBids(sortFunctions[option](upcomingBids));
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const tempBanners = await GetBanners();
        setBanners(tempBanners);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUpcomingBids = async () => {
      setUpcomingBidsState(true);
      try {
        const tempUpcomingBids = await getUpcomingBids();
        setUpcomingBids(sortFunctions[selectedSortOption](tempUpcomingBids));
        setUpcomingBidsState(false);
      } catch (error) {
        console.log(error);
        setUpcomingBidsState(false);
      }
    };

    const fetchFeaturedBids = async () => {
      setFeaturedBidsState(true);
      try {
        const tempFeaturedBids = await getFeaturedBids();
        setFeaturedBids(tempFeaturedBids);
        setFeaturedBidsState(false);
      } catch (error) {
        console.log(error);
        setFeaturedBidsState(false);
      }
    };

    window.scrollTo(0, 0);
    
    fetchBanners();
    fetchFeaturedBids();
    fetchUpcomingBids();
  }, []);

  useEffect(() => {
    if (location.hash === "#featured") {
      featuredRef.current.scrollIntoView();
    } else if (location.hash === "#upcoming") {
      upcomingRef.current.scrollIntoView();
    }
  }, [location, location.hash]);

  // function useHashOnScroll(ids) {
  //   useEffect(() => {
  //     const handleScroll = () => {
  //       const center = window.innerHeight / 2;
  //       const elements = ids.map((id) => document.getElementById(id));
  //       const centerElements = elements.filter(
  //         (element) =>
  //           element.offsetTop - center < window.pageYOffset &&
  //           element.offsetTop + element.offsetHeight - center >
  //             window.pageYOffset
  //       );
  //       if (centerElements?.length > 0) {
  //         window.location.hash = centerElements[0].id;
  //       }
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, [ids]);
  // }

  return (
    <Container>
      <Banner banners={banners} />
      <FeaturedBids
        featuredRef={featuredRef}
        featuredBids={featuredBids}
        featuredBidsState={featuredBidsState}
      />
      <UpcomingBids
        upcomingRef={upcomingRef}
        upcomingBids={upcomingBids}
        upcomingBidsState={upcomingBidsState}
        selectedSortOption={selectedSortOption}
        handleChangeSortOption={handleChangeSortOption}
        sortOptions={sortOptions}
        token={token}
      />
    </Container>
  );
}

export default Home;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
