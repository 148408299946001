import axiosInstance from "../../../utils/axios";

const getBid = async (id) => {
  try {
    const response = await axiosInstance.get(`/bid/id/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export default getBid;
