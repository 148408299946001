import React from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { CONSTANTS } from "../utils/globals";

function Layout() {
  return (
    <>
      <PageContainer>
        <Navbar />
        <OutletContainer>
          <Outlet />
        </OutletContainer>
        <Footer />
      </PageContainer>
    </>
  );
}

export default Layout;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const OutletContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: ${CONSTANTS.lightgrey};
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-bottom: 0;
    min-height: 200px;
  }
`;
