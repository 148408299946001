import React, { useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import styled from "styled-components/macro";
import upArrow from "../../images/icons/upArrow.png";
import BidLanding from "./ItemComponents/BidLanding";
import BidInformation from "./ItemComponents/BidInformation";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import getBid from "./ItemComponents/d_Bid";
import { AuthContext } from "../../context/AuthContext";
import { CONSTANTS, SOCKET_URL } from "../../utils/globals";
import jwtDecode from "jwt-decode";
import { Col, Container, Row } from "react-bootstrap";
import { CategoriesContext } from "../../context/CategoriesContext";
import { getCategories } from "../Auctions/AuctionComponents/d_Auctions";
import { SocketContext } from "../../context/SocketContext";

function Bid() {
  const [currentBid, setCurrentBid] = useState(null);
  const [tempBid, setTempBid] = useState("");
  const [currentBidder, setCurrentBidder] = useState(null);
  const [currentPrice, setCurrentPrice] = useState("");
  const [wheelItems, setWheelItems] = useState([]);
  const [decodedJwt, setDecodedJwt] = useState(null);
  const [newBidAmount, setNewBidAmount] = useState(1);
  const [dateLeft, setDateLeft] = useState(null);
  const [isExploding, setIsExploding] = useState(false);

  const { token } = useContext(AuthContext);
  const { setSubNavbarLinks } = useContext(CategoriesContext);
  const { socket, socketConnected } = useContext(SocketContext);

  const { bidId } = useParams();
  const [categories, setCategories] = useState([]);

  // const [socket, setSocket] = useState(null);
  // const [socketConnected, setSocketConnected] = useState(false);
  const [socketFlag, setSocketFlag] = useState(false);

  const [activeImage, setActiveImage] = useState("");

  const activeImageRef = useRef(null);
  const imageWheelRef = useRef(null);

  const handleActiveImage = (item) => {
    setActiveImage(item);
  };

  const decodeJwt = (token) => token && jwtDecode(token);

  const handleNextImage = () => {
    const currentIndex = wheelItems?.findIndex((item) => item === activeImage);
    const nextIndex = (currentIndex + 1) % wheelItems?.length;
    imageWheelRef.current.scrollTop += 75;

    handleActiveImage(wheelItems[nextIndex]);
  };

  const handlePreviousImage = () => {
    const currentIndex = wheelItems?.findIndex((item) => item === activeImage);
    const previousIndex =
      (currentIndex - 1 + wheelItems?.length) % wheelItems?.length;
    imageWheelRef.current.scrollTop -= 75;

    handleActiveImage(wheelItems[previousIndex]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const decodedJwt = decodeJwt(token);
    setDecodedJwt(decodedJwt);
  }, [token]);

  useEffect(() => {
    const fetchBid = async () => {
      try {
        const response = await getBid(bidId);
        setCurrentBid(response);
        setActiveImage(response?.item.images[0]);
        setTempBid(response?.minBidIncrement);

        if (response?.item?.images) setWheelItems(response.item.images);

        if (response?.currentPrice) setCurrentPrice(response.currentPrice);
        // console.log(response.currentBidder)

        if (response?.currentBidder?.username)
          setCurrentBidder(response.currentBidder.username);

        if (response?.minBidIncrement)
          setNewBidAmount(response.minBidIncrement);

        if (response?.bidStatus) {
          const { bidStatus } = response;
          if (bidStatus === "OPEN") {
            const date = new Date(response?.endDate);
            setDateLeft(date);
          } else if (bidStatus === "UPCOMING") {
            const date = new Date(response?.startDate);
            setDateLeft(date);
          }
        }

        setSocketFlag(true);
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBid();
    if (categories.length === 0) {
      getCategories().then((categories) => {
        setSubNavbarLinks(categories);
        setCategories(categories);
      });
    }
  }, [bidId]);

  useEffect(() => {
    if (socketFlag && socket && socketConnected) {
      handleJoinRoom(socket);

      socket.on("bidPlaced", (data) => {
        setCurrentPrice(JSON.stringify(data.currentPrice));
        setCurrentBidder(data.currentBidder);
      });
    }
  }, [socketFlag, bidId, socketConnected, socket]);

  const handleJoinRoom = (tempSocket) => {
    tempSocket?.emit("joinBid", {
      bidId: currentBid?._id,
    });
  };

  const handlePlaceBid = async (e) => {
    e.preventDefault();
    await axiosInstance
      .post(
        "/bid/place",
        {
          bidId,
          price: (parseFloat(currentPrice) + parseFloat(newBidAmount)).toFixed(1),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  return (
    <>
      {/* <Container fluid className="bg-danger"> */}
      <Container
        fluid
        style={{
          marginTop: 30,
        }}
      >
        <Container
          className="p-3 mb-5"
          style={{
            backgroundColor: CONSTANTS.white,
            borderRadius: "15px",
            boxShadow: CONSTANTS.hardShadow,
          }}
        >
          <Row
            style={{
              padding: "30px 0",
            }}
          >
            <Col lg={12} className=" mx-auto">
              <Container className=" ">
                <BidLanding
                  currentBid={currentBid}
                  setCurrentBid={setCurrentBid}
                  tempBid={tempBid}
                  setTempBid={setTempBid}
                  currentPrice={currentPrice}
                  setCurrentPrice={setCurrentPrice}
                  dateLeft={dateLeft}
                  setDateLeft={setDateLeft}
                  currentBidder={currentBidder}
                  setCurrentBidder={setCurrentBidder}
                  handlePlaceBid={handlePlaceBid}
                  newBidAmount={newBidAmount}
                  setNewBidAmount={setNewBidAmount}
                  decodedJwt={decodedJwt}
                  socketConnected={socketConnected}
                  activeImage={activeImage}
                  activeImageRef={activeImageRef}
                  imageWheelRef={imageWheelRef}
                  wheelItems={wheelItems}
                  handleActiveImage={handleActiveImage}
                  handleNextImage={handleNextImage}
                  handlePreviousImage={handlePreviousImage}
                  isExploding={isExploding}
                  setIsExploding={setIsExploding}
                />
              </Container>
            </Col>
          </Row>
        </Container>
        <Container
          className="mt-5 p-3 mb-5"
          style={{
            backgroundColor: CONSTANTS.white,
            borderRadius: "15px",
            boxShadow: CONSTANTS.hardShadow,
          }}
        >
          {currentBid ? (
            <BidInformation currentBid={currentBid} />
          ) : (
            <h4 className="text-center py-5">Loading...</h4>
          )}
        </Container>
      </Container>
    </>
  );
}

export default Bid;

// const Container = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// `;

// const Wrapper = styled.div`
//   /* width: 52%; */
//   width: clamp(300px, 80%, 1000px);
//   height: 100%;
//   align-self: center;
//   margin-top: 8vh;
//   display: flex;
//   flex-direction: column;
//   gap: 3rem;
// `;
