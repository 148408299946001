import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { CONSTANTS } from "../../utils/globals";
import { CategoriesContext } from "../../context/CategoriesContext";

export default function HowItWorks() {

  const { setSubNavbarLinks } = useContext(CategoriesContext);

  useEffect(() => {
    setSubNavbarLinks([]);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Container className="text-center my-5">
        <Row className="mb-5" />
        <Title>How Does the Auction Work</Title>
        <Row className="mb-5" />
        <Row className="mb-5" />
        <HowItWorksContainer className="text-start p-md-5 mx-md-5 p-3 mx-0">
          <Col className="mx-auto">
            <StepTitle>1. Registration</StepTitle>
            <StepText className="py-3">
              Start by registering an account on the online auction platform.
              Provide the required information, such as your name, email
              address, and phone number. Proceed with phone verification and
              you're good to go!
            </StepText>
            <StepTitle>2. Browsing and Searching</StepTitle>
            <StepText className="py-3 pe-5">
              <p>
                Once you're logged in, explore the auction platform to find
                items of interest. Browse through three categories:
              </p>
              <ol className="pt-2">
                <li>
                  Bargain Bonanza: This category includes auction products that
                  are considered bargains. These items may have a relatively
                  lower value or starting price compared to other products in
                  the auction. Affordable deals and budget-friendly options.{" "}
                </li>
                <li>
                  Midway Marvels: This category includes products that fall in
                  the middle range in terms of value. These items are neither
                  extremely low nor high-end. They offer a balanced combination
                  of quality and affordability.{" "}
                </li>
                <li>
                  Luxe Enclave: The Luxe Enclave represents the high-roller
                  section of the auction. This category features premium and
                  high-value products that cater to individuals seeking luxury,
                  exclusivity, and top-tier items. The products in the Luxe
                  Enclave are known for their exceptional quality and
                  craftsmanship.
                </li>
              </ol>
            </StepText>
            <StepTitle>3. Item Details</StepTitle>
            <StepText className="py-3 pe-5">
              Click on an item to view its detailed description, including
              photos, condition, starting price, bidding increments, and any
              additional information provided by the seller. Carefully read the
              item's description to understand its specifications and any terms
              and conditions associated with it.
            </StepText>
            <StepTitle>4. Bidding</StepTitle>
            <StepText className="py-3 pe-5">
              If you decide to bid on an item, enter your bid amount. The
              bidding interface displays the current highest bid and the minimum
              increment as well as the maximum bid and the status of the item
              auction.
            </StepText>
            <StepTitle>5. Monitoring</StepTitle>
            <StepText className="py-3 pe-5">
              Keep an eye on the auction's progress by checking the item's page
              regularly. You can see the current highest bid and the time
              remaining before the auction ends. If someone outbids you, you'll
              have the option to place a higher bid if desired.
            </StepText>
            <StepTitle>6. Auction Conclusion</StepTitle>
            <StepText className="py-3 pe-5">
              When the auction's designated end time is reached, the highest
              bidder becomes the winner. If you're the winning bidder, you may
              receive notifications via email or WhatsApp. The auction team will
              provide instructions for completing the purchase, including
              payment details, delivery options, and any additional fees or
              requirements.
            </StepText>
            <StepTitle>7. Payment and Delivery</StepTitle>
            <StepText className="py-3 pe-5">
              The current payment methods are cash on delivery or pick-up. This
              involves coordinating with the delivery team and providing the
              necessary details or coordinating with the seller of the item for
              pick-up. Ensure that you understand the payment methods accepted
              and any additional costs associated with the purchase.
            </StepText>
            <StepTitle>8. Communication and Feedback</StepTitle>
            <StepText className="py-3 pe-5">
              If necessary, communicate with the seller to coordinate delivery,
              ask questions, or resolve any concerns related to the item. After
              receiving the item, you may have the opportunity to leave feedback
              or a rating for the seller, providing valuable input to the
              auction platform and other users.
            </StepText>
          </Col>
        </HowItWorksContainer>
      </Container>
    </Container>
  );
}

const HowItWorksContainer = styled(Row)`
  background-color: ${CONSTANTS.white};
  box-shadow: ${CONSTANTS.mediumShadow};
  border-radius: 15px;
`;

const Title = styled.p`
  font-size: 48px;
  font-weight: 600;
  color: ${CONSTANTS.black};
`;

const StepTitle = styled.span`
  display: inline-block;
  padding: 10px 50px;
  border-radius: 15px;
  color: ${CONSTANTS.white};
  background-color: ${CONSTANTS.orange};
  box-shadow: ${CONSTANTS.mediumShadow};
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 800;
`;

const StepText = styled.p`
  color: ${CONSTANTS.black};
  font-size: 20px;
`;
