import axios from "../../../utils/axios";

export const getRandomBid = async () => {
  try {
    const response = await axios.get("/bid/random");
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getAllBids = async () => {
  try {
    // const response = await axios.get("/bid/all");
    const response = await axios.get("/bid/open");
    // console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const GetBanners = async () => {
  try {
    const response = await axios.get("/banner");
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getFeaturedBids = async () => {
  try {
    const response = await axios.get("/bid/featured");
    return response.data.bids;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getUpcomingBids = async () => {
  try {
    const response = await axios.get("/bid/upcoming");
    return response.data.bids;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};
export const feelingLuckyPing = async () => {
  try {
    const response = await axios.get("/bid/ping");
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};
