import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { CONSTANTS, FullWidthButton, MODAL_TYPES } from "../../utils/globals";
import FormGroup from "../FormGroup";
import axios from "../../utils/axios";
import { ModalContext } from "../../context/ModalContext";
import EnterEmail from "./ForgetPasswordSteps/EnterEmail";
import VerifyCode from "./ForgetPasswordSteps/VerifyCode";
import ResetPassword from "./ForgetPasswordSteps/ResetPassword";
import { handleLogin } from "./d_Auth";
import { AuthContext } from "../../context/AuthContext";

const STEPS = {
  ENTER_EMAIL: "ENTER_EMAIL",
  VERIFY_CODE: "VERIFY_CODE",
  RESET_PASSWORD: "RESET_PASSWORD",
};

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(STEPS.ENTER_EMAIL);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(false);

  const { setToken } = useContext(AuthContext);
  const { setModalType } = useContext(ModalContext);

  const router = () => {
    if (step === STEPS.ENTER_EMAIL) {
      return (
        <EnterEmail
          email={email}
          setEmail={setEmail}
          handleSubmit={handleSubmitRequestCode}
        />
      );
    } else if (step === STEPS.VERIFY_CODE) {
      return (
        <VerifyCode
          code={code}
          setCode={setCode}
          handleSubmit={handleSubmitVerifyCode}
        />
      );
    } else if (step === STEPS.RESET_PASSWORD) {
      return (
        <ResetPassword
          password={password}
          confirmPassword={confirmPassword}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          handleSubmit={handleSubmitResetPassword}
          resetPasswordStatus={resetPasswordStatus}
        />
      );
    }
  };

  const handleRequestCode = async (email) => {
    setStep(STEPS.VERIFY_CODE);
    try {
      const response = await axios.post("/auth/resetpassword", {
        email: email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyCode = async (email, code) => {
    try {
      const response = await axios.post("/auth/verifycode", {
        email: email,
        code: code,
      });
      setStep(STEPS.RESET_PASSWORD);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetPassword = async (email, password) => {
    try {
      const response = await axios.post("/auth/changepasswithcode", {
        email: email,
        code: code,
        password: password,        
      });
      setResetPasswordStatus(true);
      setModalType(MODAL_TYPES.LOGIN);
    } catch (error) {
      setResetPasswordStatus(false);
      console.log(error);
    }
  };

  const handleSubmitRequestCode = (e) => {
    e.preventDefault();
    handleRequestCode(email);
  };

  const handleSubmitVerifyCode = (e) => {
    e.preventDefault();
    handleVerifyCode(email, code);
  };

  const handleSubmitResetPassword = (e) => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    e.preventDefault();
    handleResetPassword(email, password);
  };

  return <React.Fragment>{router()}</React.Fragment>;
}

export default ForgetPassword;

const Title = styled.h2`
  font-weight: 600;
  /* font-size: 2.5rem; */
  text-align: center;
  color: ${CONSTANTS.black};
  margin-bottom: 2.75rem;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 15px;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${CONSTANTS.black};
  text-align: center;

  &.orange {
    color: ${CONSTANTS.orange};
  }

  &.clickable {
    cursor: pointer;
  }

  &.withUnderline {
    &::after {
      content: "";
      width: 0;
      height: 1px;
      display: block;
      position: relative;
      bottom: -5px;
      /* background-color: ${CONSTANTS.orange}; */
      background-color: #fff;
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s ease-in-out;
    }
  }
`;
