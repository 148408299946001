import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CONSTANTS } from "../../utils/globals";
import styled from "styled-components";
import { CategoriesContext } from "../../context/CategoriesContext";

export default function AboutUs() {
  const { setSubNavbarLinks } = useContext(CategoriesContext);

  useEffect(() => {
    setSubNavbarLinks([]);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Container className="text-center">
        <Row className="mb-5" />
        <Row className="mb-5" />
        <Title>About us</Title>
        <Row className="mb-4" />
        <Row className="px-lg-5 px-0">
          <Col xs={12} lg={5} className="mx-auto px-4">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <ImageWrapper
                src={require("../../images/aboutus/hammer.png")}
                alt="hammer"
              />
              <TextWrapper>
                Auctionable is an online auction store that is set to
                revolutionize the e-commerce landscape in Lebanon. As the
                country's first and only online auction store, we are excited to
                introduce a concept that will bring the thrill of bidding and
                the joy of discovering unique collectibles right to your
                fingertips.
              </TextWrapper>
            </Row>
          </Col>
          <Col></Col>
          <Col xs={12} lg={5} className="mx-auto px-4">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <ImageWrapper
                src={require("../../images/aboutus/bid.png")}
                alt="hammer"
              />
              <TextWrapper>
                Our platform offers a diverse range of collectibles, ranging
                from low-value items to luxurious and highly coveted pieces.
                Whether you are a seasoned collector or an enthusiast looking to
                embark on a new journey, Auctionable will be your go-to
                destination for finding treasures that are not easily accessible
                elsewhere.
              </TextWrapper>
            </Row>
          </Col>
        </Row>
        <Row className="px-lg-5 px-0">
          <Col lg={5} className="mx-auto px-4">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <ImageWrapper
                src={require("../../images/aboutus/officepeople.png")}
                alt="hammer"
              />
              <TextWrapper>
                Our team has an impressive track record, having worked on
                multiple high-profile projects. We are confident that this
                experience will translate into an exceptional auction platform
                that brings together buyers and sellers in a single, accessible
                space.
              </TextWrapper>
            </Row>
          </Col>
        </Row>
        <Row className="px-lg-5 px-0">
          <Col xs={12} lg={5} className="mx-auto px-4">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <ImageWrapper
                src={require("../../images/aboutus/laptop.png")}
                alt="hammer"
              />
              <TextWrapper>
                With cutting-edge technology and a user-friendly interface, we
                have created a platform that ensures a seamless and enjoyable
                bidding experience. We take pride in our reliable technology
                infrastructure, which guarantees a smooth and efficient auction
                process.
              </TextWrapper>
            </Row>
          </Col>
          <Col></Col>
          <Col xs={12} lg={5} className="mx-auto px-4">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <ImageWrapper
                src={require("../../images/aboutus/creditpay.png")}
                alt="hammer"
              />
              <TextWrapper>
                At Auctionable, we understand the value of customer
                satisfaction. That's why we prioritize fast and reliable
                customer support, ensuring that your concerns and queries are
                promptly addressed. We also strive for swift and secure
                delivery, making your auctioned items reach you in no time.
              </TextWrapper>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

const TextWrapper = styled.div`
  line-height: 1.4rem;
  padding: 30px;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  text-align: center;
  background-color: ${CONSTANTS.white};
  color: ${CONSTANTS.black};
  z-index: 2;
  border-radius: 15px;
  box-shadow: ${CONSTANTS.hardShadow};
`;

const ImageWrapper = styled.img`
  transform: translateY(20%);
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Title = styled.p`
  font-size: 48px;
  font-weight: 600;
  color: ${CONSTANTS.black};
`;
