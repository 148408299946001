import React, { useState } from "react";
import styled from "styled-components/macro";
import FormGroup from "../FormGroup";
import { FullWidthButton } from "../../utils/globals";

function OTPStep({ otp, setOtp, handleSignup }) {
  return (
    <form onSubmit={handleSignup}>
      <FormGroup
        state={otp}
        setState={setOtp}
        Title="ENTER OTP"
        placeholder="OTP"
        type="text"
        required
        maxLength="5"
      />
      <FullWidthButton type="submit" disabled={otp === ""}
      >
        START NOW
      </FullWidthButton>
    </form>
  );
}

export default OTPStep;
