import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home/Home";
import Item from "./pages/Item/Bid";
import { AuthContextProvider } from "./context/AuthContext";
import { ModalContextProvider } from "./context/ModalContext";
import "bootstrap/dist/css/bootstrap.min.css";
import HowItWorks from "./pages/HowItWorks/HowItWorks";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import { CategoriesContextProvider } from "./context/CategoriesContext";
import Auctions from "./pages/Auctions/Auctions";
import AboutUs from "./pages/AboutUs/AboutUs";
import { SocketContextProvider } from "./context/SocketContext";
import ContactUs from "./pages/ContactUs/ContactUs";

function App() {
  return (
    <>
      <AuthContextProvider>
        <ModalContextProvider>
          <CategoriesContextProvider>
            <SocketContextProvider>
              <Routes>
                {/* Routes with Navbar and Footer */}
                <Route path="/" element={<Layout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/auctions" element={<Auctions />} />
                  <Route path="bid/id/:bidId" element={<Item />} />
                  <Route path="how-it-works" element={<HowItWorks />} />
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="contact-us" element={<ContactUs />} />
                  <Route path="terms" element={<TermsAndConditions />} />
                </Route>
              </Routes>
            </SocketContextProvider>
          </CategoriesContextProvider>
        </ModalContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
