import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { CONSTANTS } from "../utils/globals";
import countryCodes from "../data/country_codes.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function FormGroup({
  state,
  setState,
  type,
  placeholder,
  title,
  required,
  multiline,
  phoneGroup,
  styles,
  extraPadding,
  ...props
}) {
  return (
    <Container styles={styles}>
      <Title>{title}</Title>
      {phoneGroup ? (
        <>
          <PhoneInput
            enableAreaCodeStretch={true}
            enableSearch={true}
            value={state}
            onChange={(phone) => setState(phone)}
            // showDropdown={true}
            specialLabel=""
            searchStyle={{
              padding: 10,
              width: "90%",
              border: `1px solid ${CONSTANTS.lightgrey2}`,
              borderRadius: "5px",
            }}
            inputStyle={{
              backgroundColor: "transparent",
              width: "100%",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius: "5px",
            }}
            inputClass="phoneInputTest"
            searchPlaceholder="Search country"
          />
        </>
      ) : !multiline ? (
        <>
          <CustomInput
            type={type}
            value={state}
            onChange={(e) => setState(e.target.value)}
            required={required}
            {...props}
            extraPadding={extraPadding}
            multiline={multiline}
          />
        </>
      ) : (
        <>
          <CustomTextArea
            type={type}
            value={state}
            onChange={(e) => setState(e.target.value)}
            required={required}
            {...props}
            extraPadding={extraPadding}
            multiline={multiline}
          />
        </>
      )}
    </Container>
  );
}

export default FormGroup;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;

  ${({ styles }) => styles}
`;

const Title = styled.span`
  display: block;
  text-align: left;
  color: ${CONSTANTS.black};
  font-weight: 600;
  font-size: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  & > select {
    width: 30%;
    height: 35px;
    border: 1px solid ${CONSTANTS.lightgrey2};
    background-color: transparent;
    color: ${CONSTANTS.black};
    font-weight: 600;
    font-size: 16px;

    &:focus {
      outline: transparent;
    }
  }
`;

const CustomInput = styled.input`
  border: 1px solid ${CONSTANTS.lightgrey2};
  width: 100%;
  height: 35px;
  background-color: transparent;
  border-radius: 5px;
  padding: ${({ extraPadding }) => (extraPadding ? "20px" : "0")};
  padding-left: 3%;

  &:focus {
    outline: transparent;
  }
`;

const CustomTextArea = styled.textarea`
  border: 1px solid ${CONSTANTS.lightgrey2};
  width: 100%;
  height: 235px;
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  padding-left: 3%;
  padding-top: 3%;
  resize: none;

  &:focus {
    outline: transparent;
  }
`;
