import styled from "styled-components/macro";

export const CONSTANTS = {
  orange: "#FF9900",
  lightgrey: "#f7f8fa",
  lightgrey2: "#D9D9D9",
  black: "#636363",
  grey: "#A8A8A8",
  white: "#FEFEFE",
  red: "#F93F3F",
  red2: "#F54620",

  // hardShadow: "0px 0px 12px 4px rgba(0, 0, 0, 0.1)",
  softShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
  mediumShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  hardShadow: "0px 0px 12px 1px rgba(0, 0, 0, 0.1)",
};

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://192.168.18.106:5000"
    : "https://auction.api.madripple.com";

export const SOCKET_URL =
  process.env.NODE_ENV === "development"
    ? "http://192.168.18.106:5000"
    : "https://auction.api.madripple.com";

export const MODAL_TYPES = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export const FullWidthButton = styled.button`
  width: ${({ width }) => width || "100%"};
  border: none;
  outline: transparent;
  background-color: #ff9900;
  color: white;
  font-weight: 800;
  font-size: 14px;
  padding: 10px 0;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  /* handle disabled state */

  &:disabled {
    background-color: ${CONSTANTS.black};
    color: ${CONSTANTS.white};
    transition: all 0.2s ease-in-out;
    cursor: not-allowed;
  }

  ${({ styles }) => styles}
`;

export const SectionTitle = styled.h2`
  margin: 50px 0;
  font-weight: 600;
  font-size: 36px;
  color: ${CONSTANTS.black};
  text-align: center;
`;

export const NotFoundText = styled.h2`
  display: block;
  height: 100%;
  font-weight: 600;
  font-size: 24px;
  color: ${CONSTANTS.black};
  text-align: center;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
`;