import React, { createContext, useContext, useEffect, useState } from "react";
import { SOCKET_URL } from "../utils/globals";
import { AuthContext } from "./AuthContext";
import { io } from "socket.io-client";

const SocketContext = createContext();

const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const { token } = useContext(AuthContext);

  const handleDisconnectSocket = (tempSocket) => {
    tempSocket.disconnect();
    setSocketConnected(false);
  };

  useEffect(() => {
    const socketVar = io(
      SOCKET_URL,
      token && {
        query: {
          token: token,
        },
      }
    );

    socketVar.on("connect", () => {
      setSocket(socketVar);
      localStorage.setItem("auctionSocket", socketVar);
      setSocketConnected(true);
    });

    socketVar.on("disconnect", () => handleDisconnectSocket(socketVar));
  }, [token]);

  return (
    <SocketContext.Provider value={{ socket, setSocket, socketConnected }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContextProvider, SocketContext };
