import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components/macro";
import logoIcon from "../images/icons/logo.png";
import poweredByMadRipple from "../images/icons/poweredByMadRipple.png";
import LogoName from "./LogoName";
import { Link } from "react-router-dom";
import { CONSTANTS, MODAL_TYPES } from "../utils/globals";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import { Tag } from "antd";

function Footer() {
  const { token } = useContext(AuthContext);
  const { openModal } = useContext(ModalContext);

  return (
    <>
      <Container fluid className="footer" style={{ padding: 0 }}>
        <Container>
          <FooterTopSection>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LogoIcon src={logoIcon} alt="logo" />
              <LogoName
                containerStyles={`
                  width: fit-content;
                  height: auto;
                  `}
              />
            </Row>
            <PoweredByContainer>
              <img src={poweredByMadRipple} alt="powered by mad ripple" />
            </PoweredByContainer>
          </FooterTopSection>
        </Container>
        <hr className="footer-rule" />
        <Container>
          <FooterBottomSection className="text-center text-md-start">
            <Col lg="3">
              <FooterLinksContainer>
                <FooterLinksTitle>About Us</FooterLinksTitle>
                <FooterLinksWrapper>
                  <FooterLink to="/about-us">About</FooterLink>
                  <FooterLink to="/contact-us">Contact</FooterLink>
                </FooterLinksWrapper>
              </FooterLinksContainer>
            </Col>
            <Col lg="3">
              <FooterLinksContainer>
                <FooterLinksTitle>For Bidders</FooterLinksTitle>
                <FooterLinksWrapper>
                  <FooterLink to="/#upcoming">See Upcoming Auctions</FooterLink>
                  <FooterLink
                    onClick={() => openModal(MODAL_TYPES.SIGNUP)}
                    className={token ? "isHidden" : ""}
                  >
                    Join Auctionable
                  </FooterLink>
                </FooterLinksWrapper>
              </FooterLinksContainer>
            </Col>
            <Col lg="3">
              <FooterLinksContainer>
                <FooterLinksTitle>For Dealers</FooterLinksTitle>
                <FooterLinksWrapper>
                  <span to="#">
                    Sell on Auctionable <Tag color="orange">Coming Soon!</Tag>
                  </span>
                  <FooterLink to="/contact-us">Request Information</FooterLink>
                </FooterLinksWrapper>
              </FooterLinksContainer>
            </Col>
            <Col lg="3">
              <FooterLinksContainer>
                <FooterLinksTitle>Resources</FooterLinksTitle>
                <FooterLinksWrapper>
                  <FooterLink to="/terms">Auction Terms</FooterLink>
                  <FooterLink to="/how-it-works">How Auction Works</FooterLink>
                </FooterLinksWrapper>
              </FooterLinksContainer>
            </Col>
          </FooterBottomSection>
        </Container>
      </Container>
    </>
  );
}

export default Footer;

const FooterTopSection = styled(Row)`
  margin: 50px 0 30px;
  width: 100%;
`;

const LogoIcon = styled.img`
  height: 68px;
  width: 68px;
  object-fit: cover;
  padding: 0;
`;

const PoweredByContainer = styled(Row)`
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 45px;
    width: auto;
    object-fit: cover;
  }
`;

const FooterBottomSection = styled(Row)`
  padding: 50px 0;

  /* style the Cols inside this component */
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid white;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
`;

const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterLinksTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  color: ${CONSTANTS.black};
`;

const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;

  @media (max-width: 768px) {
    align-items: center;
  }

  & > span {
    color: ${CONSTANTS.grey};
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    width: max-content;

    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

// fix the underline, through the width of the link
const FooterLink = styled(Link)`
  color: ${CONSTANTS.grey};
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  width: max-content;

  &::after {
    /* underline */
    content: "";
    display: block;
    width: 0;
    background: ${CONSTANTS.black};
    height: 1px;
    transition: width 0.3s;

    /* positioning */
    position: relative;
  }

  &:hover::after {
    width: 100%;
    transition: width 0.3s;
  }

  &.isHidden {
    visibility: hidden;
    pointer-events: none;
  }
`;
