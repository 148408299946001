import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

function Banner({ banners }) {
  let bannerItems = [];

  if (banners || banners?.length > 0) {
    bannerItems = banners.map((banner) => ({
      src: banner,
      alt: "Banner",
    }));
  }

  return (
    <Container fluid>
      <Container className={"my__carousel_main"}>
        <Carousel interval={3500} controls={false}>
          {/* <Carousel.Item
          style={{
            height: "400px",
          }}
        >
          <img
            className=" w-100"
            src="https://picsum.photos/800/400?random=3"
            alt="First slide"
          />
        </Carousel.Item> */}
          {bannerItems.map((item, idx) => (
            <Carousel.Item
              key={idx}
              style={{
                height: "400px",
              }}
            >
              <img
                className=" w-100"
                src={item.src}
                alt={item.alt}
                style={{ objectFit: "contain" }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </Container>
  );
}

export default Banner;
